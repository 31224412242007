<template>
  <div class="draftLineWrap" :class="(draft.prepared && draft.prepared.typeString) ? draft.prepared.typeString : ''">
    <div class="lineitem first l-checkbox">
      <div class="checkbox" v-if="draft.id">
        <input type="checkbox" :id="'id_'+draft.id"  :value="draft.id" v-if="draftList && draftList.selected" v-model="draftList.selected[draft.id]"/>
        <label :for="'id_'+draft.id"></label>
      </div>
    </div>
    <div class="lineitem l-state">
      <template v-if="draft.draft_state === '1'">
        <span tooltip="Aktiv" position="right"><i class="fa-duotone fa-circle-check text-success"></i></span>
      </template>
      <template v-if="draft.draft_state === '-1'">
        <span tooltip="Archiviert" position="right"><i class="fa-duotone fa-boxes-packing text-secondary"></i></span>
      </template>
      <template v-if="draft.draft_state === '-2'">
        <span tooltip="Gelöscht" position="right"><i class="fa-duotone fa-trash text-danger"></i></span>
      </template>
    </div>
    <div class="lineitem l-title longTitle" @click="setDraft(draft)">
      {{ draft.title }}
    </div>
    <div class="lineitem l-category longTitle" @click="setDraft(draft)">
      {{ draft.categoryTitle }}
    </div>
    <div class="lineitem l-attachments" >
      <template v-if="draft.attachment_id">
        <button class="btn btn-sm btn-outline-primary" @click="openAttachment(draft.attachment_id)" tooltip="Anhang öffnen" position="left">
          <i class="fa-duotone fa-paperclip"></i>
        </button>
      </template>
    </div>
    <div class="lineitem last ml-auto">
      <template v-if="draftActive && draftActive.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeDraft');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setDraft(draft)"></i></template>
    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import {useToast} from "vue-toastification";
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";

export default {
  name: "Headline",
  data() {
    return {
      modalContent: null,
      sendContent: false,
      sendContentTitle: '',
      sendContentText: '',
      orderConfirmationText: '',
      toast: useToast(),
      address: {},
      notice: {
        document_id: null,
        notice_message: ''
      },
      storno:{
        title: '',
        message:'',
        reason: ''
      },
      fileUrl: '',
      loading: false,
      folders: [],
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  props: {
    draft: Object,
  },
  components: {
    Editor2
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "getConfigs", "draftList"])
  },
  methods: {
    setDraft(draft) {
      this.$store.dispatch("setActiveDraft", draft);
      this.$router.push({name: 'DraftDetails', params: {id: draft.id}})

    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    openAttachment(id) {

      this.loading = true;
      const headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'files/uploadTemp/' + id, { headers }).then(response => {

        // Check if the file was not found
        if (response.data.result && !response.data.result.success) {
          this.$store.dispatch("toastError", response.data.todo.content || "File not found"); // Display toast with API message or default message
          this.loading = false;
          return; // Exit the function early
        }

        const base64Response = response.data.data; // Base64 encoded data
        this.folders = response.data.folders;
        const mime = this.file.mime_type; // MIME type from the API response
        const byteCharacters = atob(base64Response);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: mime });
        this.fileUrl = URL.createObjectURL(blob); // Create a URL for the Blob

        // Download the file directly
        const link = document.createElement('a');
        link.href = this.fileUrl;
        link.download = this.file.title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.loading = false;
      }).catch(error => {
        this.$store.dispatch("toastError", "Datei nicht verfügbar.");
        this.loading = false;
      });
    }

  }

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
