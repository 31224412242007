<template>
  <div class="card">
    <div class="card-body">
      <Loading :loading="loading" v-if="loading" :type="'inner'" style="position: relative;" :height="'250px'"/>
      <div style="max-height: 300px;overflow-y: scroll; overflow-x: hidden;" v-else>
        <div class="activityWrap" v-if="files && files.length > 0">
          <div class="row d-flex mb-3 pb-3" v-for="file in files" v-bind:key="file.id">
            <div class="col-lg-5 col-md-5 col-12  justify-content-center align-self-center">
              <p class="mb-0"> {{ file.title }}</p>
            </div>
            <div class="col-lg-3 col-md-3 col-6  justify-content-center align-self-center">
              <p class="mb-0"><span tooltip="hochgeladen am" position="right">{{ $filters.formatDateTime(file.uploaded_on) }}</span></p>
            </div>
            <div class="col-lg-4 col-md-4 col-6  justify-content-center align-self-center">
              <button class="btn btn-sm btn-outline-danger float-end me-2 ms-3" @click="deleteFile(file.id)">
          <span tooltip="Löschen" position="left">
          <i class="fa-trash-alt fa-duotone"></i></span></button>
              <template
                v-if="file.contentType && (file.contentType.includes('image') || file.contentType.includes('pdf'))">
                <button tooltip="Vorschau" position="left" type="button"
                        class="btn btn-outline-primary btn-sm float-end" @click="previewFile(file.id, file.type)"><i
                  class="fa-duotone fa-eye"></i>
                </button>
              </template>
              <template v-else>
                <button type="button" tooltip="Download" position="left"
                        class="btn btn-outline-primary btn-sm float-end" @click="previewFile(file.id, file.type)"><i
                  class="fa-duotone fa-download"></i>
                </button>
              </template>
            </div>
          </div>
        </div>
        <div v-else><p class="noItemsFound">{{ $t("activity.noEntriesFound") }}</p></div>
      </div>
    </div>
  </div>
  <template v-if="file">
    <Modal v-if="modalsList.previewFile">
      <div class="modal show" style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              {{ file.title }}
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">
              <!-- new-->
              <template v-if="file.contentType ==='application/pdf'  ">
                <vue-pdf-embed style="min-height: 80vh;" :source="'data:'+file.contentType+';base64,'+file.content"/>
              </template>
              <template v-else-if="file.contentType && file.contentType.includes('image')">
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="btn-group">
                      <button @click="zoomIn" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-plus"></i></button>
                      <button @click="zoomOut" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-minus"></i></button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <p class="mb-1"><small><i class="fa-duotone fa-computer-mouse-scrollwheel color-blue"></i> Das
                      Mausrad kann zum Zoomen verwendet werden</small>
                    </p></div>
                </div>
                <div class="row" @wheel.prevent="handleWheel($event)">
                  <div class="col-12" style="overflow: hidden;">
                    <img :src="'data:'+file.contentType+';base64,'+file.content"
                         :style="{ transform: `scale(${zoomLevel})`, transformOrigin: transformOrigin }"
                         style="display: block; width: 100%; height: auto;">
                  </div>
                </div>
              </template>
              <template v-else>
                <iframe :src="'data:'+file.contentType+';base64,'+file.content"
                        :style="(file.contentType === 'application/pdf') ? 'min-height:80vh;': ''"></iframe>
              </template>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VuePdfEmbed from "vue-pdf-embed";
import Loading from "@/components/Loading.vue";

export default {
  name: "CustomerFiles",
  components: {Loading, VuePdfEmbed},
  computed: {
    ...mapGetters(["getEmployees", "customerActive", "modalsList"]),
  },
  props: {
    customer: {}
  },
  data() {
    return {
      files: [],
      file: '',
      fileInfo: {
        text: '',
        type: 'files'
      },
      zoomLevel: 1,
      loading: false,
      transformOrigin: 'center center'
    };
  },
  methods: {
    getFiles() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/files", {headers}).then((r) => {
        this.files = r.data.result.data;
        this.loading = false;
      });
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    previewFile(attachmentId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/readFile/" + attachmentId + "/" + type, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          // show in modal
          this.$store.commit('openModal', 'previewFile');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFile(attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // confirm delete first
      this.$confirm('Soll diese Datei gelöscht werden?', 'Datei löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#0088CC',
        type: 'warning'
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "attachment/" + attachmentId, {headers: headers})
          .then((response) => {

            this.getFiles();
          })
          .catch((error) => {
            console.log(error);
          });
      }).catch((err) => {
        console.log(err);
      });
    },
    zoomIn() {
      this.zoomLevel += 0.1;
    },
    zoomOut() {
      if (this.zoomLevel > 0.8) this.zoomLevel -= 0.1;
    },
    handleWheel(event) {
      const target = event.target; // This should be your image or embed element
      const rect = target.getBoundingClientRect();
      // Calculate cursor position inside the element as a percentage
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
      // Update transformOrigin based on cursor position
      this.transformOrigin = `${xPercent}% ${yPercent}%`;
      // Zoom in or out
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    handleFileReload() {
      this.getFiles();
    },
    downloadFile(customerId, attachmentId) {
      this.buttonLoading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
          this.buttonLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.buttonLoading = false;
        });
    },
  },
  created() {
    this.getFiles();
    window.addEventListener('triggerReloadFiles', this.handleFileReload);
  },
  beforeDestroy() {
    window.removeEventListener('triggerReloadFiles', this.handleFileReload);
  },
  watch: {
    customerActive() {
      this.getFiles();
    }
  }
};
</script>
