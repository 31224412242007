<template>
  <div class="card">

    <div class="card-body">
      <Loading :loading="loading" v-if="loading" :type="'inner'" style="position: relative;" :height="'250px'"/>
      <div v-else style="max-height: 300px;overflow-y: scroll; overflow-x: hidden;">
        <div class="activityWrap" v-if="activities && activities.length > 0">
          <div class="row d-flex mb-3 pb-3" v-for="activity in activities" v-bind:key="activity.id">
            <div class="col-12  justify-content-center align-self-center">
              <p class="mb-0 font-weight-bold text-sm"> <img :src="$filters.employeeIcon(getEmployees, activity.u_id, 'u_id')" class="sketchAvatar"> {{ activity.formattedDate }} <span position="right" :tooltip="activity.a_type" v-html="renderTypeIcon(activity.a_type)"></span> </p>
              <p class="mb-0 text-xs" v-html="activity.a_description"></p>
            </div>

          </div>
        </div>
        <div v-else><p class="noItemsFound">{{$t("activity.noEntriesFound")}}</p></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";

export default {
  name: "CustomerActivities",
  components: {Loading},
  computed: {
    ...mapGetters(["getEmployees", "customerActive"]),


  },
  props: {
    customer: {}
  },
  data() {
    return {
      activities: [],
      loading: false
    };
  },

  methods: {
    renderTypeIcon(type) {
      if (typeof type !== 'string') {
        console.error('Invalid type passed to renderTypeIcon:', type);
        return ''; // Fallback if the type is invalid
      }
      if (type.includes('document') || type.includes('voucher') || type.includes('document_queue') || type.includes('documentactivation')) {
        return '<i style="color:#0088CC;" class="fa-duotone fa-file"></i>';
      }
      if (type.includes('payment')) {
        return '<i style="color:#b21f28;" class="fa-duotone fa-euro-sign"></i>';
      }
      if (type.includes('customer')|| type.includes('project') || type.includes('address') || type.includes('access')|| type.includes('registration')) {
        return '<i style="color:#0088CC;" class="fa-user fa-duotone"></i>';
      }
      if (type.includes('mahnung')) {
        return '<i style="color:#b21f28;" class="fa-duotone fa-exclamation-triangle"></i>';
      }
      if (type.includes('phone')){
        return '<i style="color:#0088CC;" class="fa-duotone fa-phone-alt"></i>';
      }
      if (type.includes ('mail') || type.includes('newsletter')) {
        return '<i style="color:#0088CC;" class="fa-duotone fa-mail-bulk"></i>';
      }
      if (type.includes ('notice')){
        return '<i style="color:#0088CC;" class="fa-duotone fa-sticky-note"></i>';
      }
      if (type.includes('archive')){
        return '<i  style="color: #3B8130;" class="fa-duotone fa-archive"></i>';
      }
      if(type.includes('Opos') || type.includes('sepa')) {
        return '<i style="color:#b21f28;" class="fa-receipt fa-duotone"></i>';
      }
      if(type.includes ('extensions')){
        return '<i style="color: #3B8130;" class="fa-duotone fa-ellipsis-h"></i>';
      }
      return '<span class="badge badge-primary badge-xs ms-2 mb-2">'+type+'</span>'; // Fallback for unmatched cases
    },
    getActivities() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/activities", {headers}).then((r) => {
        this.activities = r.data.result.data;
        this.loading = false;
      });
    }
  },
  created() {
    this.getActivities();
  },
  watch: {
    customerActive() {
      this.getActivities();
    }
  }
};
</script>
