<template>

  <div class="row" v-if="stats && stats.data">
    <div class="col-6">
      <div class="card">
        <div class="card-body positionchart" style="min-height: 100vh;">
          <div class="form-group">
            <label for="dateSelect">Datum wählen: </label>
            <select id="dateSelect" class="form-select" v-model="selectedDate" @change="updateChart">
              <option value="">- Bitte Datum wählen -</option>
              <option v-for="item in stats.data" :key="item.date" :value="item.date">
                {{ $filters.formatDate(item.date) }}
              </option>
            </select>
          </div>
          <PieChart :chartData="barChartStats" :options="barChartOptions" />
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body positionchart" style="min-height: 100vh;">
          <div class="form-group">
            <label for="dateSelect">Datum wählen: </label>
            <select id="dateSelect" class="form-select" v-model="selectedDate2" @change="updateChart">
              <option value="">- Bitte Datum wählen -</option>
              <option v-for="item in stats.data" :key="item.date" :value="item.date">
                {{ $filters.formatDate(item.date) }}
              </option>
            </select>
          </div>
          <PieChart :chartData="barChartStats2" :options="barChartOptions2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PieChart } from "vue-chart-3";

export default {
  name: "DFBPosition",
  props: {
    stats: {
      type: Object,
      default: () => ({ data: [] })
    },
    compareDate: {
      type: String,
      default: ""
    }
  },
  components: {
    PieChart
  },
  data() {
    return {
      selectedDate: "", // This will store the selected date
      selectedDate2: "", // This will store the selected date
      barChartStats: {
        labels: [],
        datasets: [
          {
            label: 'Accepted',
            data: [],
            backgroundColor: [], // Will be filled dynamically
          },
          {
            label: 'Denied',
            data: [],
            backgroundColor: [], // Will be filled dynamically
          }
        ]
      },
      barChartStats2: {
        labels: [],
        datasets: [
          {
            label: 'Accepted',
            data: [],
            backgroundColor: [], // Will be filled dynamically
          },
          {
            label: 'Denied',
            data: [],
            backgroundColor: [], // Will be filled dynamically
          }
        ]
      },
      barChartOptions: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false, // Disable x-axis display
            beginAtZero: true,
          },
          y: {
            display: false, // Disable y-axis display
            beginAtZero: true,
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            }
          }
        },
        plugins: {
          legend: {
            position: 'top',
          }
        },
      },
      barChartOptions2: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false, // Disable x-axis display
            beginAtZero: true,
          },
          y: {
            display: false, // Disable y-axis display
            beginAtZero: true,
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            }
          }
        },
        plugins: {
          legend: {
            position: 'top',
          }
        },
      }
    };
  },
  methods: {
    prepareChartData(date) {
      const labels = [];
      const acceptedData = [];
      const deniedData = [];
      const acceptedColors = [];
      const deniedColors = [];
      // Define a list of colors
      const colorMapping = {
        'Cheftrainer': '#188360',
        'Co-Trainer': '#4A90E2',
        'Sontiges': '#27AE60',
        'Default': '#7F8C8D' // Fallback color if no match is found
      };
      const selectedData = this.stats.data.find(item => item.date === date);
      if (selectedData) {
        for (const key in selectedData.data) {
          if (selectedData.data.hasOwnProperty(key)) {
            const league = selectedData.data[key];
            labels.push(league.name);
            acceptedData.push(league.countAccepted);
            deniedData.push(league.countDenied);
            // Assign colors based on the league name
            const color = colorMapping[league.name] || colorMapping['Default'];
            acceptedColors.push(color);
            deniedColors.push(color);
          }
        }
        this.barChartStats.labels = labels;
        this.barChartStats.datasets[0].data = acceptedData;
        this.barChartStats.datasets[0].backgroundColor = acceptedColors; // Assign colors dynamically
        this.barChartStats.datasets[1].data = deniedData;
        this.barChartStats.datasets[1].backgroundColor = deniedColors; // Assign colors dynamically
      }
    },
    prepareChartData2(date) {
      const labels = [];
      const acceptedData = [];
      const deniedData = [];
      const acceptedColors = [];
      const deniedColors = [];
      // Define a list of colors
      const colorMapping = {
        'Cheftrainer': '#188360',
        'Co-Trainer': '#4A90E2',
        'Sontiges': '#27AE60',
        'Default': '#7F8C8D' // Fallback color if no match is found
      };
      const selectedData = this.stats.data.find(item => item.date === date);
      if (selectedData) {
        for (const key in selectedData.data) {
          if (selectedData.data.hasOwnProperty(key)) {
            const league = selectedData.data[key];
            labels.push(league.name);
            acceptedData.push(league.countAccepted);
            deniedData.push(league.countDenied);
            // Assign colors based on the league name
            const color = colorMapping[league.name] || colorMapping['Default'];
            acceptedColors.push(color);
            deniedColors.push(color);
          }
        }
        this.barChartStats2.labels = labels;
        this.barChartStats2.datasets[0].data = acceptedData;
        this.barChartStats2.datasets[0].backgroundColor = acceptedColors; // Assign colors dynamically
        this.barChartStats2.datasets[1].data = deniedData;
        this.barChartStats2.datasets[1].backgroundColor = deniedColors; // Assign colors dynamically
      }
    },
    updateChart() {
      this.prepareChartData(this.selectedDate);
      this.prepareChartData2(this.selectedDate2);
    }
  },
  mounted() {
    if (this.stats.data.length > 0) {
      // Find the closest match to the compareDate (ignoring time for simplicity)
      const defaultDate = this.stats.data.find(item => item.date.startsWith(this.compareDate.split(' ')[0]));
      if (defaultDate) {
        this.selectedDate = defaultDate.date;
      } else {
        this.selectedDate = this.stats.data[0].date; // Fallback to the first available date
      }
      this.prepareChartData(this.selectedDate);
    }
  }
};
</script>
<style scoped>
.positionchart .chart-container {
  min-height: 100vh; /* Set a minimum height to ensure the container is tall */
  height: 100vh; /* Ensure the container height fills the viewport */
}

.card-body.positionchart {
  padding: 0; /* Optional: Reduce padding to maximize space for the chart */
}

.positionchart .BarChart {
  height: 100%; /* Ensure the chart takes up all available height */
}
</style>
