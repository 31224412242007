
<template>

  <div class="helpbox" :class="color" v-if="layout === 'big'">
    <div class="helpbox__header" :class="(showHelpBox) ? 'open': 'closed'">

      <h4 class="helpbox__title"  @click="toggleHelpBox" >
        <i class="fa-duotone" :class="'fa-'+icon"></i> {{title}}
        <button class="helpbox__close">
          <i class="fa-duotone" :class="(showHelpBox) ? 'fa-chevrons-up' : 'fa-chevrons-down'"></i>
        </button></h4>

    </div>
    <div class="helpbox__content" v-if="showHelpBox">
      <div class="content">
        <slot>
          {{ content }} <!-- Fallback content if no slot is provided -->
        </slot>
      </div>
    </div>
  </div>
  <div class="smallHelpBox" :class="color" v-if="layout === 'small'">
    <i class="fa-duotone fa-xl" :class="'fa-'+icon"  style="margin-bottom: 12px; float: left; margin-top: 22px; margin-right: 12px;"></i> <slot>
    {{ content }} <!-- Fallback content if no slot is provided -->
  </slot>
  </div>


</template>
<script>
export default {
  name: 'HelpBox',
  props: {
    title: {
      type: String,
      default: 'Hilfe'
    },
    content: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'blue'
    },
    icon: {
      type: String,
      default: 'info-square'
    },
    layout: {
      type: String,
      default: 'big'
    }
  },
  data() {
    return {
      showHelpBox: false
    }
  },
  methods: {
    toggleHelpBox() {
      this.showHelpBox = !this.showHelpBox
    }
  }
}
</script>
<style scoped lang="scss">
</style>
