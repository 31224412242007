<template>
  <div class="card-body draft-list">

    <Sort @sort="resortOrder($event)" />
    <p class="text-sm text-secondary p-3" v-if="!getConfigs || (getConfigs && getConfigs.costNumbers && getConfigs.costNumbers.length === 0)">Es wurden keine Kostenstellen
      gefunden.</p>
      <div  class="draft-item" v-for="draft in getConfigs.costNumbers"
            :key="draft.id">
        <Headline :draft="draft"/>
      </div>
    <router-link to="/config/accounting" class="mt-5 mb-5" style="display: block;font-size: 14px;color: #b21f28;"><i class="fa-duotone fa-chevron-left"></i> {{$t('nav.back')}}</router-link>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Accounting/CostNumbers/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Accounting/CostNumbers/Layouts/Sort.vue";
import $ from "jquery";

export default {
  name: "DraftSearch",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },

  components: {
    Sort,
    Headline,
  },
  methods: {

    getCostNumbers(){
      this.$store.dispatch("costNumbersFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.costNumbers.ordering.value = payload.type;
        this.$store.state.filters.costNumbers.direction.value = payload.direction;
      } else {
        this.$store.state.filters.costNumbers.ordering.value = payload.type
      }
      this.getCostNumbers();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {

  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
