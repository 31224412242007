<template>
  <div class="paginationWrapper mb-3">
    <ul class="pagination d-flex justify-content-center mt-3">
      <li class="page-item" :class="{disabled: (offset === 0)}">
        <template v-if="offset === 0">
          <button class="page-link disabled" href="#" aria-label="Previous">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
          </button>
        </template>
        <template v-else>
          <button @click="emitSetPage('previous')" class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
          </button>
        </template>
      </li>
      <template v-for="pageNum in totalPages" :key="pageNum">
        <!-- Show the first page -->
        <li v-if="pageNum === 1 && totalPages > 1" class="page-item" :class="{ active: currentPage === 1 }">
          <button @click="emitSetPage(pageNum)" class="page-link">1</button>
        </li>

        <!-- Show ... after the first page if currentPage is greater than 4 (and there are more than 7 total pages) -->
        <li v-if="currentPage > 4 && totalPages > 7 && (pageNum === 1 )" class="page-item">
          <span class="page-link">...</span>
        </li>

        <!-- Conditionally render page numbers: Show 3 before & after the current, but not the first or last page -->
        <li v-if="pageNum !== 1 && pageNum !== totalPages && pageNum >= currentPage - 3 && pageNum <= currentPage + 3" class="page-item" :class="{ active: currentPage === pageNum }">
          <button @click="emitSetPage(pageNum)" class="page-link">{{ pageNum }}</button>
        </li>

        <!-- Show ... before the last page if currentPage is less than totalPages - 3 (and there are more than 7 total pages) -->
        <li v-if="currentPage < totalPages - 3 && totalPages > 7 && pageNum === totalPages - 1" class="page-item">
          <span class="page-link">...</span>
        </li>

        <!-- Show the last page -->
        <li v-if="pageNum === totalPages  && totalPages > 1" class="page-item" :class="{ active: currentPage === totalPages }">
          <button @click="emitSetPage(totalPages)" class="page-link">{{ totalPages }}</button>
        </li>
      </template>

      <li class="page-item" :class="{disabled: (offset + limit >= complete || complete < limit)}">
        <template v-if="offset + limit >= complete || complete < limit">
          <button class="page-link" aria-label="Next">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
          </button>
        </template>
        <template v-else>
          <button class="page-link" @click="emitSetPage('next')" aria-label="Next">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
          </button>
        </template>
      </li>
    </ul>
    <div class="overviewCounter float-end mb-3">
      <span v-if="complete > 0">
        Einträge: {{ offset + 1 }} -
        {{ Math.min(offset + limit, complete) }}
        von {{ complete }}
      </span>
      <span v-else>Keine Einträge</span>
    </div>
    <div class="limitBox float-start mb-3" v-if="!fixedLimit">
      <multiselect :model-value="fixedLimit || limit" :options="listLimits" v-if="limit"
                   :multiple="false"
                   :canClear="false"
                   :closeOnSelect="true"
                   :placeholder="'Anzahl wählen'"
                   openDirection="top"
                   @update:model-value="$emit('update:limit', $event)"
                   class="form-select small">
      </multiselect>
    </div>

  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
export default {
  name: "Pagination",
  components: {
    Multiselect,
  },
  props: ["offset", "limit", "complete", "setPage", "onLimitChange", "fixedLimit"],
  data() {
    return {
      listLimits: [5, 10, 15, 20, 50, 100],
    }
  },
  computed: {
    totalPages() {
      const limit = this.fixedLimit || this.limit;
      return this.complete > 0 ? Math.ceil(this.complete / limit) : 0;
    },
    currentPage() {
      const limit = this.fixedLimit || this.limit;
      return Math.floor(this.offset / limit) + 1;
    }
  },
  methods: {
    emitSetPage(page) {
      this.$emit('set-page', page);
    }
  }
}
</script>
