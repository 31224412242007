<template>
  <div class="filesystemFullWrap" :class="(compactView) ? 'compactView': ''">
    <div class="card" style="border-radius: 0;">
      <div class="card-header" v-if="!compactView || !returnFile">
        <button class="btn btn-outline-primary btn-sm me-2" @click="logic = 'database'; navigateHash('home');refreshFiles();"><i
          class="fa-duotone fa-file-archive"></i>
          Home
        </button>
        <button class="btn btn-outline-secondary btn-sm me-2" @click="logic = 'database';navigateHash('invoices')"><i
          class="fa-duotone fa-folder-bookmark"></i> Rechnungen
        </button>
        <button class="btn btn-outline-secondary btn-sm me-2" @click="navigateServer('images'); activeHash = ''"
                v-if="getMenu && getMenu.events">
          <i class="fa-duotone fa-image"></i> Public
        </button>
        <button class="btn btn-outline-primary btn-sm" v-if="logic === 'server' && currentPath !=='images'"
                @click="serverNavigateLevelUp" tooltip="Zurück"><i
          class="fa-duotone fa-share-from-square fa-flip-horizontal"></i></button>
        <button class="btn btn-outline-danger btn-sm float-end" @click="deleteFolderServer(currentPath)"
                v-if="currentPath && logic==='server'"><span :tooltip="currentPath+' löschen?'"><i
          class="fa-duotone fa-folder"></i> Löschen</span></button>
      </div>
      <div class="card-body" v-if="!loading">
        <template v-if="currentPath && logic === 'server'">
          <div class="breadcrumbs"><small><span tooltip="Serverpfad"><i
            class="fa-duotone fa-list-tree"></i> {{ currentPath }}</span></small></div>
        </template>
        <div class="folders" v-if="filesystem">
          <template v-for="singleFolder in filesystem">
            <div v-if="logic === 'database'" class=" documentbox category" @click="navigateHash(singleFolder.hash)">
                  <span class="icon">
                  <template v-if="singleFolder.icon === 'icon-folder'"><i
                    class="fa-duotone fa-folder"></i><span class="sumItems">{{
                      singleFolder._sumItems
                    }}</span></template></span>
              <span class="text">{{ singleFolder.title }}</span>
            </div>
            <div v-else class=" documentbox category" @click="navigateServer(singleFolder.folder)">
                  <span class="icon">
                  <template v-if="singleFolder.icon === 'icon-folder'"><i
                    class="fa-duotone fa-folder"></i><span class="sumItems">{{
                      singleFolder._sumItems
                    }}</span></template></span>
              <span class="text">
                     {{ singleFolder.title }}
                      </span>
            </div>
          </template>
        </div>
        <div class="files documentLineWrap">
          <template v-if="fileitems.length > 0  && !compactView ">
            <div class=" documentline lineitems file">
              <div class="lineitem l-number" v-if="logic ==='database'">
                #
              </div>
              <div class="lineitem l-title">Titel</div>
              <div class="lineitem l-date" v-if="logic ==='database'">Hochgeladen</div>
              <div class="lineitem l-date" v-if="logic === 'database'">Belegdatum</div>
              <div class="lineitem l-size" v-if="logic === 'server'">Dateigröße</div>
              <div class="lineitem l-mime" v-if="logic === 'server'">Typ</div>
              <template v-if="activeHash ==='invoices'">
                <div class="lineitem l-type">Typ</div>
                <div class="lineitem l-account">Konto</div>
                <div class="lineitem l-type">Stufe</div>
                <div class="lineitem l-type">Preis</div>
                <div class="lineitem l-type">Offen</div>
              </template>
              <div class="lineitem l-action"></div>
            </div>
          </template>
          <template v-for="singleFile in fileitems">
            <div class=" documentline lineitems file" @click="navigateHash(singleFile.hash)"
                 :class="(singleFile._opos && singleFile._opos.partPaymentClasses) ? singleFile._opos.partPaymentClasses : ''">
              <div class="lineitem l-number" v-if="logic ==='database'  && !compactView">
                {{ singleFile.id }}
              </div>
              <div class="lineitem l-title" @click="showFile(singleFile)">{{ singleFile.title }}
                <template v-if="singleFile.customer_id">
                  <br>
                  <small>
                    <template v-if="singleFile.c_company">
                      {{ singleFile.c_company }} -
                    </template>
                    {{ singleFile.c_firstname }} {{ singleFile.c_lastname }}</small>
                </template>
                <template
                  v-if="singleFile._opos && singleFile._opos.partPaymentClasses && singleFile._opos.partPaymentClasses === 'type_crm storno'">
                  &nbsp;<span tooltip="Storno" style="color: #b21f28;" position="right"><i
                  class="fa-duotone fa-rectangle-xmark"></i></span>
                </template>
              </div>
              <div class="lineitem l-date" v-if="logic ==='database'">{{
                  $filters.formatDate(singleFile.uploaded_on)
                }}
              </div>
              <div class="lineitem l-date" v-if="logic ==='database'  && !compactView">
                <template v-if="activeHash ==='invoices'"><span tooltip="Rechnungsdatum" position="left">{{
                    $filters.formatDate(singleFile.invoice_date)
                  }}</span><br><span tooltip="Zahlungsziel" position="left">{{
                    $filters.formatDate(singleFile.pay_due)
                  }}</span></template>
                <template v-else>{{ $filters.formatDate(singleFile.doc_date) }}</template>
              </div>
              <template v-if="logic ==='server'">
                <div class="lineitem l-size">{{ formatBytes(singleFile.size) }}</div>
                <div class="lineitem l-mime"><span :tooltip="singleFile.mimetype">
                  <template
                    v-if="singleFile.mimetype && (singleFile.mimetype.includes('image') || singleFile.mimetype.includes('svg'))"><i
                    class="fa-duotone fa-image"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('pdf')"><i
                    class="fa-duotone fa-file-pdf"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('zip')"><i
                    class="fa-duotone fa-file-archive"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('word')"><i
                    class="fa-duotone fa-file-word"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('excel')"><i
                    class="fa-duotone fa-file-excel"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('powerpoint')"><i
                    class="fa-duotone fa-file-powerpoint"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('text')"><i
                    class="fa-duotone fa-file-alt"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('audio')"><i
                    class="fa-duotone fa-file-audio"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('video')"><i
                    class="fa-duotone fa-file-video"></i></template>
                  <template v-if="singleFile.mimetype && singleFile.mimetype.includes('code')"><i
                    class="fa-duotone fa-file-code"></i></template>
                </span></div>
              </template>
              <template v-if="activeHash ==='invoices'">
                <div class="lineitem l-type">
                  <template v-if="singleFile.type==='crm'">
                    <span tooltip="Ausgehende Rechnung (CRM)" style="color: #3B8130;">
                    <i class="fa-duotone fa-arrow-right"></i></span>
                  </template>
                  <template v-if="singleFile.type==='opo'">
                    <span tooltip="Fehlende Rechnung" style="color: #b21f28;">
                    <i class="fa-duotone fa-file-excel"></i></span>
                  </template>
                  <template v-if="singleFile.type==='out'">
                    <span tooltip="Ausgehende Rechnung (extern)" style="color: #3B8130;">
                    <i class="fa-duotone fa-arrow-alt-left"></i></span>
                  </template>
                  <template v-if="singleFile.type==='in'">
                    <span tooltip="Eingehende Rechnung">
                    <i class="fa-duotone fa-arrow-alt-left"></i></span>
                  </template>
                </div>
                <div class="lineitem l-account content">
                  {{ singleFile._accountTitle }}<br>{{ singleFile.accounting_year }} -
                  {{ $t('accountingPeriods.' + singleFile.accounting_period) }}
                </div>
                <div class="lineitem l-type">{{ singleFile.mahnstufe }}</div>
                <div class="lineitem l-type">{{ $filters.priceEuro(singleFile.price) }}</div>
                <div class="lineitem l-type">{{ $filters.priceEuro(singleFile.balance) }}</div>
              </template>
              <div class="lineitem l-action ml-auto">
                <template v-if="returnFile">
                  <button type="button" class="btn btn-outline-primary btn-sm me-1" tooltip="Datei verwenden"
                          @click="returnFileEmitter(singleFile)"><i
                    class="fa-duotone fa-check"></i></button>
                </template>
                <template v-if="logic === 'server'">
                  <button type="button" tooltip="Ansehen" @click="showFileServer(singleFile)"
                          class="btn btn-outline-primary btn-sm"><i
                    class="fa-duotone fa-magnifying-glass"></i></button>
                  <button type="button" class="btn btn-outline-primary btn-sm ms-1"
                          @click="deleteFileServer(singleFile)"
                          tooltip="Löschen"><i class="fa-duotone fa-trash"></i></button>
                </template>
                <template v-if="logic === 'database'">
                  <button type="button" tooltip="Bearbeiten" @click="showFile(singleFile)"
                          class="btn btn-outline-primary btn-sm"><i
                    class="fa-duotone fa-edit"></i></button>
                  <button type="button" class="btn btn-outline-primary btn-sm ms-1" @click="deleteFile(singleFile)"
                          tooltip="Löschen"><i class="fa-duotone fa-trash"></i></button>
                  <template v-if="activeHash ==='invoices'">
                    <button type="button" tooltip="Zahlung erfassen" @click="showFile(singleFile)"
                            class="btn btn-outline-primary btn-sm ms-1"><i
                      class="fa-duotone fa-wallet"></i></button>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div class="paginationWrapper mb-3" v-if="activeHash ==='invoices'">
          <ul class="pagination d-flex justify-content-center mt-3">
            <li class="page-item" :class="{disabled: (offset === 0)}">
              <template v-if="offset === 0">
                <button class="page-link disabled" href="#" aria-label="Previous">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
                </button>
              </template>
              <template v-else>
                <button @click="setPage('previous')" class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
                </button>
              </template>
            </li>
            <li class="page-item"
                :class="{disabled: ((offset + limit) >= complete || complete < limit)}">
              <template v-if="((offset + limit) >= complete || complete < limit)">
                <button class="page-link" aria-label="Next">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
                </button>
              </template>
              <template v-else>
                <button class="page-link" @click="setPage('next')" aria-label="Next">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
                </button>
              </template>
            </li>
          </ul>
          <template v-if="activeHash">
            <div class="overviewCounter float-end mb-3">
      <span v-if="complete > 0">
  Einträge: {{ offset + 1 }} -
  {{ Math.min(offset + limit, complete) }}
  von {{ complete }}
</span>
              <span v-else>Keine Einträge</span>
            </div>
            <div class="limitBox float-start mb-3">
              <Multiselect v-model="limit" :options="listLimits" v-if="limit"
                           :multiple="false"
                           :canClear="false"
                           open-direction="top"
                           :closeOnSelect="true"
                           :placeholder="'Anzahl wählen'"
                           openDirection="top"
                           @update:model-value="offset = 0; navigateHash(activeHash)"
                           class="form-select small">
              </Multiselect>
            </div>
          </template>
        </div>
      </div>
      <template v-else>
        <div class="card-body" style="height: 300px;" v-if="logic === 'database'">
          <Loading :loading="loading" height="auto"/>
        </div>
      </template>
    </div>
  </div>
  <Modal v-if="modalsList.filesystem" class="wrapper Files">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="card" v-if="fileActive">
            <h6 class="card-header">{{ fileActive.title }}
              <button type="button" class="btn btn-close float-end ms-4"
                      @click="$store.commit('closeModal', 'filesystem'); fileUrl = null; zoomLevel = 1;">
              </button>
            </h6>
            <div class="card-body" v-if="logic === 'database'">
              <div class="row">
                <div class="col-lg-4 col-12">
                  <template v-if="activeHash === 'invoices'">
                    <div class="form-group">
                      <label>Betrag</label>
                      <input type="number" v-model="fileActive.price" step="0.01">
                    </div>
                    <div class="form-group">
                      <label>Buchungsdatum</label>
                      <VueDatePicker v-model="fileActive.pay_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Buchungsdatum" :auto-apply="true"
                                     :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                                     cancel-text="X" select-text="Wählen" :teleport="true"
                                     class="small"
                      />
                    </div>
                    <div class="form-group">
                      <label>Konto</label>
                      <Multiselect class="small" v-if="onlyfolders"
                                   :options="getConfigs.bankAccounts"
                                   :multiple="false"
                                   label="title"
                                   :internal-search="true"
                                   :close-on-select="true"
                                   :clean-clear="true"
                                   :clear-on-search="true"
                                   :show-labels="false"
                                   v-model="fileActive.account_id"
                                   :valueProp="'id'"
                                   :searchable="true"
                                   :closeOnSelect="true"
                                   :noOptionsText="$t('filters.noOptionsText')"/>
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-outline-primary" @click="putInvoiceFile(fileActive)"><i
                        class="fa-duotone fa-floppy-disk"></i> Speichern
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="fileActive.title">
                    </div>
                    <div class="form-group">
                      <VueDatePicker v-model="fileActive.doc_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Belegdatum / Eingangsdatum" :auto-apply="true"
                                     :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                                     cancel-text="X" select-text="Wählen" :teleport="true"
                                     class="small"
                      />
                    </div>
                    <div class="form-group">
                      <Multiselect class="small" v-if="onlyfolders"
                                   :options="onlyfolders"
                                   :multiple="false"
                                   label="title"
                                   :internal-search="true"
                                   :close-on-select="true"
                                   :clean-clear="true"
                                   :clear-on-search="true"
                                   :show-labels="false"
                                   v-model="fileActive.folder"
                                   :valueProp="'id'"
                                   :searchable="true"
                                   :closeOnSelect="true"
                                   :noOptionsText="$t('filters.noOptionsText')"/>
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="fileActive.new_folder" class="form-control"
                             placeholder="Neuer Ordner"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="checkbox" class="form-check-input" v-model="fileActive.visibility"
                             id="invoiceVisible"><label
                      for="invoiceVisible"> Für Kunden sichtbar?</label>
                    </div>
                    <div class="form-group" v-if="fileActive.customer_id">
                      Zugewiesen an: {{ fileActive.customer_id }}
                    </div>
                    <div class="form-check form-check-inline" v-if="fileActive.customer_id">
                      <input type="checkbox" class="form-check-input" v-model="assignCustomer" id="assignCustomer">
                      <label
                        for="assignCustomer">Kundenzuweisung ändern</label>
                    </div>
                    <div class="form-group">
                      <customersearch v-if="assignCustomer || !fileActive.customer_id" v-model="fileActive.customer_id"
                                      @changed-customer="handleCustomerChange"/>
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-outline-primary" @click="putFile(fileActive)"><i
                        class="fa-duotone fa-floppy-disk"></i> Speichern
                      </button>
                    </div>
                  </template>
                </div>
                <div class="col-12 col-lg-8" style="position: relative;">
                  <Loading v-if="loadingFile" :loading="loadingFile" type="inner" height="350px"/>
                  <!-- Download file button-->

                  <template v-else>
                    <template v-if="fileUrl">
                      <a :href="fileUrl" class="btn btn-outline-primary btn-sm float-end mb-3"
                         download><i
                        class="fa-duotone fa-download"></i> Download</a></template>
                    <template
                      v-if="fileMime === 'image/jpeg' || fileMime === 'image/png' || fileMime === 'image/svg' || fileMime && fileMime.includes('image') ">
                      <img :src="'data:'+fileMime+';base64,'+fileEncoded"
                           style="width: 100%; display: block; height: auto;"/>
                    </template>
                    <template v-else>
                      <div style="overflow-x: hidden; width: 100%; overflow-y: auto;">
                        <VuePdfEmbed v-if="fileUrl" :source="fileUrl" style="width: 100%; height: 650px;"></VuePdfEmbed>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <div class="card-body" v-if="logic === 'server' && fileActive">
              <template
                v-if="fileActive.mimetype === 'image/jpeg' || fileActive.mimetype === 'image/png' || fileActive.mimetype === 'image/svg' || fileActive.mimetype && fileActive.mimetype.includes('image') ">
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="btn-group">
                      <button @click="zoomIn" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-plus"></i></button>
                      <button @click="zoomOut" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-minus"></i></button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <p class="mb-1"><small><i class="fa-duotone fa-computer-mouse-scrollwheel color-red"></i> Das
                      Mausrad kann zum Zoomen verwendet werden</small>
                    </p></div>
                </div>
                <div class="row" @wheel.prevent="handleWheel($event)">
                  <div class="col-12" style="overflow: hidden;">
                    <img :src="fileActive.fullPath"
                         style="margin-left: auto;margin-right: auto;max-width: 100%; display: block; height: auto;"
                         :style="{ transform: `scale(${zoomLevel})`, transformOrigin: transformOrigin }"/>
                  </div>
                </div>
              </template>
              <template v-else>
                <div style="overflow-x: hidden; width: 100%; overflow-y: auto;">
                  <VuePdfEmbed v-if="fileActive.fullPath" :source="fileActive.fullPath"
                               style="width: 100%; height: 650px;"></VuePdfEmbed>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import customersearch from "@/filter/components/customersearch.vue";
import Multiselect from '@vueform/multiselect'
import Loading from "@/components/Loading.vue";
import VuePdfEmbed from "vue-pdf-embed";
import {nextTick} from "vue";

export default {
  name: 'Filesystem',
  data() {
    return {
      loading: true,
      loadingFile: true,
      currentPath: '',
      percent: 0,
      assignCustomer: false,
      type: 'full',
      activeHash: false,
      zoomLevel: 1,
      transformOrigin: 'center center',
      filesystem: [],
      onlyfolders: [],
      fileitems: [],
      fileActive: null,
      fileEncoded: false,
      fileMime: null,
      fileUrl: null,
      logic: 'database',
      offset: 0,
      complete: 0,
      listLimits: [5, 10, 15, 20, 50, 100],
      limit: 20,
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  computed: {
    ...mapGetters(["modalsList", "getConfigs", "getPlugins", "getMenu"])
  },
  components: {
    VuePdfEmbed,
    Loading,
    VueDatePicker, Multiselect, customersearch
  },
  props: {
    directFolder: {
      type: String,
      default: ""
    },
    returnFile: {
      type: Boolean,
      default: false
    },
    compactView: {
      type: Boolean,
      default: false
    },
    directHash: {
      type: String,
      default: ''
    }
  },
  emits: ['returnFile'],
  methods: {
    nextTick,
    zoomIn() {
      this.zoomLevel += 0.1;
    },
    zoomOut() {
      if (this.zoomLevel > 0.8) this.zoomLevel -= 0.1;
    },
    returnFileEmitter(singleFile) {
      this.$emit('returnFile', singleFile);
    },
    handleWheel(event) {
      const target = event.target; // This should be your image or embed element
      const rect = target.getBoundingClientRect();
      // Calculate cursor position inside the element as a percentage
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
      // Update transformOrigin based on cursor position
      this.transformOrigin = `${xPercent}% ${yPercent}%`;
      // Zoom in or out
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    refreshFiles() {
      if (this.logic === 'database') {
        this.loading = true;
        let headers = this.headers;
        axios.get(this.$store.state.apiUrl + 'documentBox/search?limit=' + this.limit + '&offset=' + this.offset, {headers}).then(response => {
          this.filesystem = response.data.result.data;
          this.fileitems = response.data.result.files;
          this.complete = response.data.result.complete;
          this.loading = false;
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      }
    },
    formatBytes(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    onlyFolders() {
      if (this.logic === 'database') {
        this.loading = true;
        let headers = this.headers;
        // todo-matt: api call for only folders
        axios.get(this.$store.state.apiUrl + 'documentBox/search', {headers}).then(response => {
          this.onlyfolders = response.data.result.data;
          this.complete = response.data.result.complete;
          if (this.directHash) {
            this.navigateHash(this.directHash);
          }
          this.loading = false;
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      }
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    showFile(singleFile) {
      this.$store.commit('openModal', 'filesystem');
      this.logic = 'database';
      this.fileActive = singleFile;
      this.loadingFile = true;
      this.getFileDetails(singleFile);
      this.getFilePreview(singleFile);
    },
    showFileServer(singleFile) {
      this.logic = 'server';
      this.$store.commit('openModal', 'filesystem');
      this.loading = false;
      this.loadingFile = false;
      this.fileActive = singleFile;
    },
    navigateHash(hash) {
      if (this.logic === 'database' && hash) {
        this.loading = true;
        let headers = this.headers;
        this.activeHash = hash;
        axios.get(this.$store.state.apiUrl + 'documentBox/search/' + hash + '?limit=' + this.limit + '&offset=' + this.offset, {headers}).then(response => {
          this.filesystem = response.data.result.data;
          this.fileitems = response.data.result.files;
          this.complete = response.data.result.complete;
          this.logic = 'database';
          this.loading = false;
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      }
    },
    navigateServer(folder) {
      this.loading = true;
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'cdn/' + folder + '?limit=' + this.limit + '&offset=' + this.offset, {headers}).then(response => {
        this.filesystem = response.data.result.data;
        this.fileitems = response.data.result.files;
        this.complete = response.data.result.complete;
        this.loading = false;
        this.logic = 'server';
        this.currentPath = folder;
      }).catch((error) => {
        this.$store.dispatch('handleApiResponse', error.response)
      });
    },
    deleteFile(singleFile) {
      let headers = this.headers;
      let VueInner = this;
      this.$confirm("Datei löschen", 'Sicher löschen?', 'warning', {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning',
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + 'documentBox/' + singleFile.id, {headers}).then(response => {
          VueInner.navigateHash(VueInner.activeHash);
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      });
    },
    deleteFileServer(singleFile) {
      let headers = this.headers;
      let VueInner = this;
      this.$confirm("Datei löschen", 'Sicher löschen?', 'warning', {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning',
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + 'cdn/' + singleFile.path, {headers}).then(response => {
          VueInner.navigateServer(VueInner.currentPath);
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      });
    },
    deleteFolderServer(path) {
      let headers = this.headers
      let VueInner = this;
      this.$confirm("Ordner löschen", 'Sicher löschen?', 'warning', {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning',
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + 'cdn/' + path, {headers}).then(response => {
          VueInner.navigateServer(VueInner.currentPath);
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      });
    },
    handleCustomerChange(customer) {
      this.fileActive.customer_id = customer.id;
    },
    getFileDetails(singleFile) {
      let headers = this.headers;
      let apimiddlepath = 'documentBox/';
      if (this.activeHash === 'invoices') {
        apimiddlepath = 'documentBoxInvoices/';
      }
      axios.get(this.$store.state.apiUrl + apimiddlepath + singleFile.id, {headers}).then(response => {
        this.fileActive = response.data.result.data;
        this.fileInfos = response.data;
      });
    },
    getFilePreview(singleFile) {
      let headers = this.headers;
      let apimiddlepath = 'documentBox/';
      if (this.activeHash === 'invoices') {
        apimiddlepath = 'documentBoxInvoices/';
      }
      axios.get(this.$store.state.apiUrl + apimiddlepath + singleFile.id + '/preview', {headers}).then(response => {
        this.fileEncoded = response.data.result.data;
        const base64Response = response.data.result.data;
        this.fileMime = response.data.result.mime;
        const mime = response.data.result.mime; // MIME type from the API response
        const byteCharacters = atob(base64Response);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: mime});
        this.fileUrl = URL.createObjectURL(blob); // Create a URL for the Blob
        this.loadingFile = false;
      });
    },
    serverNavigateLevelUp() {
      let headers = this.headers;
      let VueInner = this;
      let path = this.currentPath.split('/');
      path.pop();
      path = path.join('/');
      this.navigateServer(path);
    },
    putFile(singleFile) {
      let headers = this.headers;
      let postContent = this.fileActive;
      let VueInner = this;
      axios.put(this.$store.state.apiUrl + 'documentBox/' + this.fileActive.id, postContent, {headers}).then(response => {
        VueInner.navigateHash(VueInner.activeHash);
        VueInner.$store.commit('closeModal', 'filesystem');
      }).catch((error) => {
        VueInner.$store.dispatch('handleApiResponse', error.response)
      });
    },
    putInvoiceFile(singleFile) {
      let headers = this.headers;
      let postContent = this.fileActive;
      let VueInner = this;
      axios.put(this.$store.state.apiUrl + 'documentBox/invoices/' + this.fileActive.id, postContent, {headers}).then(response => {
        VueInner.navigateHash(VueInner.activeHash);
        VueInner.$store.commit('closeModal', 'filesystem');
      }).catch((error) => {
        VueInner.$store.dispatch('handleApiResponse', error.response)
      });
    },
    setPage(direction) {
      let type;
      type = direction;
      if (type === 'next' && this.offset <= this.complete) {
        this.offset = parseInt(this.offset) + parseInt(this.limit);
      }
      if (type === 'previous' && this.offset >= this.limit) {
        this.offset = parseInt(this.offset) - parseInt(this.limit);
      }
      this.navigateHash(this.activeHash);
    },
  },
  created() {
    this.refreshFiles();
    this.onlyFolders();
    window.addEventListener('triggerReloadFiles', this.refreshFiles);
    // wait for above actions to finish next tick
    if (this.directFolder) {
      this.navigateServer(this.directFolder);
    }
  },
  beforeUnmount() {
    window.removeEventListener('triggerReloadFiles', this.refreshFiles);
  }
}
</script>

