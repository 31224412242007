
<template>

  <div class="row" v-if="stats && stats.data">

    <div class="col mb-3" v-for="box in stats.data">


          <ul class="list-group" style="text-align: center;">
            <li class="list-group-item">{{$filters.formatDate(box.date)}}</li>
            <li class="list-group-item" tooltip="Höchstwert"><i style="color: #188360;" class="fa-duotone  fa-gauge-high"></i> {{box.points.high}}</li>
            <li class="list-group-item" tooltip="Niedrigster Wert"><i style="color: darkred;" class="fa-duotone  fa-gauge-low"></i> {{box.points.low}}</li>
            <li class="list-group-item" tooltip="Durchschnitt"><i class="fa-duotone fa-gauge-simple"></i> {{box.points.middle}}</li>
          </ul>

    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "DFBPoints",
  props: {
    stats: {
      type: Object,
      default: () => ({ data: [] })
    }
  },
  methods: {

  },
};
</script>
