<template>
  <div class="appView files redView">
    <div class="container-fluid">
      <ul class="tabNav">
        <li @click="infoLine = 'tempfiles'" :class="(infoLine === 'tempfiles') ? 'active' : ''"><i class="fa-duotone fa-file-upload"></i> Hochgeladene Dateien
        </li>
        <li @click="infoLine = 'filebrowser'" :class="(infoLine === 'filebrowser') ? 'active' : ''"><i class="fa-duotone fa-file-search"></i> Dateibrowser</li>
      </ul>
      <div class="uploader pt-3" v-if="infoLine === 'tempfiles'">
        <UploadComponent/>
        <div class="card mb-3 ">
          <h6 class="card-header"><i class="fa-duotone fa-file-upload"></i> Hochgeladene Dateien</h6>
          <List :files="tempFiles"/>
        </div>
      </div>
      <div class="fileBrowser" v-if="infoLine === 'filebrowser'">
        <Filesystem />
      </div>
    </div>
  </div>
</template>
<script>
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import axios from "axios";
import List from "@/routing/core/Files/List.vue";
import Filesystem from "@/components/Filesystem.vue";
export default {
  name: "Files",
  components: {UploadComponent, List, Filesystem},
  data() {
    return {
      tempFiles: [],
      infoLine: "tempfiles",
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  methods: {
    refreshTempFiles() {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'files/uploadTemp', {headers}).then(response => {
        this.tempFiles = response.data;
      });
    }
  },
  created() {
    this.refreshTempFiles();
    window.addEventListener('triggerReloadFiles', this.refreshTempFiles);
  },
}
</script>

