<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs.timeConfigs.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
    <Sort @sort="resortOrder($event)" />
      <div  class="draft-item" v-for="draft in getConfigs.timeConfigs"
           :key="draft.id">
        <Headline :draft="draft"/>
      </div>
    <router-link to="/config/employee" class="mt-5 mb-5" style="display: block;font-size: 14px;color: #3B8130;"><i class="fa-duotone fa-chevron-left"></i> {{$t('nav.back')}}</router-link>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Employee/TimeConfigs/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Employee/TimeConfigs/Layouts/Sort.vue";
import $ from "jquery";

export default {
  name: "TimeConfigsList",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },

  components: {
    Sort,
    Headline,
  },
  methods: {

    getTimeConfigs(){
      this.$store.dispatch("timeConfigsFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.timeConfigs.ordering.value = payload.type;
        this.$store.state.filters.timeConfigs.direction.value = payload.direction;
      } else {
        this.$store.state.filters.timeConfigs.ordering.value = payload.type
      }
      this.getTimeConfigs();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },

  created() {

  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
