<template>
  <div class="productLineWrap" :class="(product.prepared && product.prepared.typeString) ? product.prepared.typeString : ''">
<!--    <div class="lineitem first l-checkbox">-->
<!--    <div class="checkbox">-->
<!--      <input type="checkbox"-->
<!--             :id="'id_'+product.id"-->
<!--             :value="product.id"-->
<!--             v-if="productList && productList.selected"-->
<!--             v-model="productList.selected[product.id]"/>-->
<!--      <label :for="'id_'+product.id"></label>-->
<!--    </div>-->
<!--    </div>-->
    <div class="lineitem l-title" @click="setProduct(product)" >
      {{ product.p_title }}
    </div>
    <div class="lineitem l-number" @click="setProduct(product)">
      <template v-if="product.p_number" >
      {{ product.p_number }}</template>
      <template v-else>{{
          product.category
        }}
      </template>
    </div>

    <div class="lineitem l-quantity">
      {{ product.p_default_quantity }}
    </div>
    <div class="lineitem l-unit">
      {{ product.p_default_unit }}
    </div>
    <div class="lineitem l-price">
      {{ $filters.priceEuro(product.p_default_price) }}
    </div>
    <div class="lineitem l-unit">
      <span tooltip="Produkt genutzt">{{ product._used }}</span>
    </div>
    <div class="lineitem last ">
      <template v-if="productActive && productActive.id === product.id">
        <i class="fa-duotone fa-x" @click="$store.commit('closeProduct')"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setProduct(product)" ></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import $ from "jquery";

export default {
  name: "Headline",
  data() {
    return {
      modalContent: null,
      addDocNotice: false,
      orderConfirmation: false,
      orderCancelation: false,
      sendContent: false,
      sendContentTitle: '',
      sendContentText: '',
      orderConfirmationText: '',
      toast: useToast(),
      address: {},
      notice: {
        document_id: null,
        notice_message: ''
      },
      storno:{
        title: '',
        message:'',
        reason: ''
      }
    }
  },
  props: {
    product: Object,
  },
  components: {
    Editor2
  },
  computed: {
    ...mapGetters(["productActive", "getEmployees", "productList", "getConfigs"])
  },
  methods: {
    prepareStorno(document){
      this.orderCancelation = true;
      this.storno.title = document.prepared.custom_fields.document_sending.subject;
      this.storno.message = document.prepared.custom_fields.document_sending.body;
    },
    setProduct(product) {
      let VueInner = this;
      this.activeProduct = product.id;
      this.$store.dispatch("setActiveProduct", product.id).then(function () {
        VueInner.$router.push({name: "ProductDetails", params: {id: product.id}});
        VueInner.scrollToTop();
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    prepareSend(document){
      this.sendContent = true;
      this.sendContentTitle = document.prepared.custom_fields.document_sending.subject
      this.sendContentText = document.prepared.custom_fields.document_sending.body
    },
    saveContentCancel(){
      this.putAction('cancellation',{title: this.storno.title, message: this.storno.message, reason: this.storno.reason});
      this.orderCancelation = false;
    },
    saveContentText(){
      this.postAction('sendPDF',{title: this.sendContentTitle, message: this.sendContentText});
      this.sendContent = false;
    },
    saveConfirmOrder(){
      this.putAction('createOrder', {message: this.orderConfirmationText});
      this.orderConfirmation = false;
    },
    postAction(action, payload) {
      if(payload === undefined) {
        payload = {};
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.getters.getApiUrl + "products/" + this.product.id + "/" + action, payload, {headers}).then((r) => {
        this.$store.dispatch("productsFilter");
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    putAction(action, payload) {
      if(payload === undefined) {
        payload = {};
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.getters.getApiUrl + "documents/" + this.product.id + "/" + action, payload, {headers}).then((r) => {
        this.$store.dispatch("productsFilter");
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    previewAction(action) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "products/" + this.product.id + "/" + action, {headers}).then((r) => {
        // open Modal with PDF Base64
        this.modalContent = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    makeClassSave(str) {
      console.log(str);
      if (str) {
        return str.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      }
    },
    postNotice() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {message: this.notice.notice_message}
      axios.post(this.$store.state.apiUrl + "document/" + this.notice.product_id + "/notice/", postContent, {headers}).then((r) => {
        this.addDocNotice = false;
        this.notice.notice_message = '';
        this.notice.product_id = null;
        this.toast.success('Notiz wurde erfolgreich gespeichert');
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
  },
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
