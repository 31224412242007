
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="btn-toolbar  mt-2 mb-2" role="toolbar">
        <div class="btn-group wrapMobile">
          <template  v-for="stat in statstypes" :key="stat.value">
            <button @click="setStatistic(stat.value)" class="btn btn-outline-primary btn-xs" :class="(stat.value === selectedStat) ? 'active': '' ">{{stat.title}}</button>

          </template>


        </div></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="loading"><p style="text-align: center; margin-top: 48px; font-size: 48px;"><i class="fa-spinner fa-duotone fa-spin"></i></p></div>
        <div v-else>
          <div class="card">
            <div class="card-body">
              <template v-if="selectedStat === 'genders'">
                 <genders :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'points'">
                <points :stats="stats" />
              </template>
              <template v-if="selectedStat === 'ages'">
                <age :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'liga'">
                <liga :stats="stats" :compareDate="compareDate"/>
              </template>
              <template v-if="selectedStat === 'cancel'">
                <cancel :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'position'">
                <position :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'positionOld'">
                <position-old :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'age'">
                <agePart :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'school'">
                <school :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'profi'">
                <profi :stats="stats" :compareDate="compareDate" />
              </template>
              <template v-if="selectedStat === 'accept'">
                <accept :stats="stats" :compareDate="compareDate" />
              </template>
              <div v-if="debug">
              <hr>
              <pre  >
                {{stats}}
              </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import genders from "./stats/genders.vue";
import points from "./stats/points.vue";
import positionOld from "./stats/positionOld.vue";
import agePart from "./stats/agePart.vue";
import age from "./stats/age.vue";
import liga from "./stats/liga.vue";
import cancel from "./stats/cancel.vue";
import school from "./stats/school.vue";
import position from "./stats/position.vue";
import profi from "./stats/profi.vue";
import accept from "./stats/accept.vue";
import $ from "jquery";
export default {
  name: "DFBStats",
  emits: ['update'],
  props: {
    requestId: {
      type: String,
      default: null,
    },
    compareDate: {
      type: String,
      default: null,
    },
  },
  components:{
    genders,
    points,
    age,
    profi,
    positionOld,
    liga,
    accept,
    cancel,
    agePart,
    school,
    position
  },
  data() {
    return {
      stats: [],
      loading: false,
      statstypes: [
        {value:'points', title:'Punkteverteilung' },
        {value: 'genders', title:'Geschlechterverteilung'},
        {value: 'ages', title:'Altersverteilung der Mannschaften'},
        {value: 'position', title:'Position aus Profil'},
        {value:'positionOld', title:'Position aus Bewerbung' },
        {value: 'liga', title:'Ligen der Teilnehmer'},
        {value: 'cancel', title:'Abbruchgründe'},
        {value: 'age', title:'Altersverteilung der Teilnehmer'},
        {value: 'school', title:'Akademischer Abschluss'},
        {value: 'profi', title:'Thematik Ex-Profi'},
        // {value: 'accept', title:'Gründe für die Zulassung'}
      ],
      selectedStat: 'points',
      debug: false
    };
  },
  mounted() {

  },
  methods: {
    getStats(type) {
      const headers = { crmAuth: localStorage.getItem("sketchManagerToken") };
      axios.get(this.$store.state.apiUrl + "plugins/dfb/statistic/" + this.requestId + "/" + type, { headers }).then((r) => {
        this.stats = r.data.result.data;
        this.loading = false;
        // emit the type
        this.$emit('update', type);
      });

    },

    setStatistic(type) {
      this.loading = true;
      this.selectedStat = type;
      this.getStats(type);
    },
  },
  created() {
    this.getStats(this.selectedStat);
  },
};
</script>

