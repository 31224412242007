
<template>
  <div class="trainerLicence mb-2"
       v-if="customerActive && customerActive.customFields && customerActive.customFields[2] && customerActive.customFields[2].group_name === 'Trainerlizenz'">
    <label class="mb-0 font-weight-bold text-sm color-blue"><i class="fa-duotone fa-file-certificate"></i><b>&nbsp;Trainerlizenz</b></label>
    <template
      v-if="customerActive.customFields[2].fields && Object.keys(customerActive.customFields[2].fields).length > 0">
      <table class="table table-sm">
        <tbody>
        <template v-for="(field, key) in customerActive.customFields[2].fields" :key="key">
          <tr class="mb-0 text-sm" v-if="field.field_value">
            <td><b>{{ field.field_label }}: </b></td>
            <td>
              <template v-if="field.formattedValue">{{ field.formattedValue }}</template>
              <template v-else>{{ field.field_value }}</template>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </template>
  </div>
  <div class="trainerActivity" v-if="hasFilledFields">
    <label class="mb-0 font-weight-bold text-sm color-blue">
      <i class="fa-duotone fa-futbol"></i><b>&nbsp;Aktuelle Trainertätigkeit</b>
    </label>
    <template v-if="customerActive.customFields[5].fields">
      <table class="table table-sm">
        <tbody>
        <template v-for="(field, key) in customerActive.customFields[5].fields" :key="key">
          <tr class="mb-0 text-sm"
              v-if="field.field_value && typeof field.field_value === 'string' && field.field_value.trim() !== ''">
            <td><b>{{ field.field_label }}:</b></td>
            <td>
              <template v-if="field.formattedValue">{{ field.formattedValue }}</template>
              <template v-else>{{ field.field_value }}</template>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </template>
  </div>
</template>
<style >
</style>
<script >
import { mapGetters } from 'vuex';
export default {
  name: 'BDFLCustomFields',
  props: {
    customerActive: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasFilledFields() {
      if (
        this.customerActive &&
        this.customerActive.customFields &&
        this.customerActive.customFields[5] &&
        this.customerActive.customFields[5].fields
      ) {
        const fields = this.customerActive.customFields[5].fields;
        // Check if fields is an object with values or a non-empty array
        if (Array.isArray(fields)) {
          // Handle fields as an array
          return fields.some(
            field => typeof field.field_value === 'string' && field.field_value.trim() !== ""
          );
        } else if (typeof fields === "object" && Object.keys(fields).length > 0) {
          // Handle fields as an object
          return Object.values(fields).some(
            field => typeof field.field_value === 'string' && field.field_value.trim() !== ""
          );
        }
      }
      return false;
    }
  },
};
</script>
