<template>
  <Details v-if="legalActive && legalActive.id"/>
  <Sort @sort="resortOrder($event)"/>
  <div class="singleRequest" v-for="(legal,key) in legalList.data" :key="legal.id">
    <div class="plugin-item">
      <Headline :legal="legal"/>
    </div>
  </div>
  <pagination list="legals" listName="legalList" filterName="legalsFilter"  />
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import Details from "@/routing/core/Legals/Details.vue";
import Headline from "@/routing/core/Legals/Layouts/Headline.vue";
import Sort from "@/routing/core/Legals/Layouts/Sort.vue";
import Pagination from "@/filter/components/pagination.vue";

export default {
  name: 'LegalOverview',
  components: {Pagination, Sort, Details, Headline},
  computed: {
    ...mapGetters(["getEmployees", "legalList", "legalActive"]),
  },
  methods: {

    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getLegals() {
      this.$store.dispatch("legalsFilter");
    },
    resortOrder(payload) {
      if (payload.direction) {
        this.$store.state.filters.legals.ordering.value = payload.type;
        this.$store.state.filters.legals.direction.value = payload.direction;
      } else {
        this.$store.state.filters.legals.ordering.value = payload.type
      }
      this.getLegals();
    },
  },
  data() {
    return {
      legals: [],
      activeLegal: {},
    };
  },
  created() {
    this.l = this.legals;
    this.getLegals();
  },
  watch: {
    legalActive: function (newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.getLegals();
      }
    }
  }}
</script>
