<template>
  <div class="pdfEditor">
    <div class="header mb-3">
      <h5>PDF Header</h5>
      <Editor v-model="pdf.header_html"/>
      <div class="row">
        <div class="col-6">
          <div class="input-group">
            <input type="color" v-model="pdf.header_bg_color" id="header_bg_color" class="form-control"/>
            <span for="header_bg_color" class="input-group-text">Hintergrundfarbe</span>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <input type="color" v-model="pdf.header_font_color" id="header_font_color" class="form-control"/>
            <span for="header_font_color" class="input-group-text">Schriftfarbe</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer mb-3">
      <h5>PDF Footer</h5>
      <Editor v-model="pdf.footer_html"/>
      <div class="row">
        <div class="col-6">
          <div class="input-group">
            <input type="color" v-model="pdf.footer_bg_color" id="footer_bg_color" class="form-control"/>
            <span for="footer_bg_color" class="input-group-text">Hintergrundfarbe</span>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <input type="color" v-model="pdf.footer_font_color" id="footer_font_color" class="form-control"/>
            <span for="footer_font_color" class="input-group-text">Schriftfarbe</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <h5>PDF Content</h5>
      <Editor v-model="pdf.content_html"/>
      <div class="row">
        <div class="col-6">
          <div class="input-group">
            <input type="color" v-model="pdf.content_bg_color" id="content_bg_color" class="form-control"/>
            <span for="content_bg_color" class="input-group-text">Hintergrundfarbe</span>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <input type="color" v-model="pdf.content_font_color" id="content_font_color" class="form-control"/>
            <span for="content_font_color" class="input-group-text">Schriftfarbe</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "@/components/Editor2";

export default {
  name: "PdfEditor",
  components: {
    Editor
  },
  data() {
    return {
      pdf: {
        header_html: "",
        header_bg_color: "#ffffff",
        header_font_color: "#000000",
        header_bg_image: "",
        footer_html: "",
        footer_bg_color: "#ffffff",
        footer_font_color: "#000000",
        footer_bg_image: "",
        content_html: "",
        content_bg_color: "#ffffff",
        content_font_color: "#000000",
        content_bg_image: "",
      }
    };
  },
  methods: {},
};
</script>
