<template>

  <template v-if="$route && $route.matched && $route.matched.length">

<!--    <template v-if="$route.matched[0].name === 'Sepa'">-->
<!--      <button class="btn btn-outline-primary red btn-sm  me-3" :tooltip="$t('toggle.sepa')" position="left"-->
<!--              @click="$store.commit('toggleAll', 'sepas');"><i class="fa-duotone fa-check"></i></button>-->

<!--    </template>-->
<!--    <template v-if="$route.matched[0].name === 'Queue'">-->
<!--      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('toggle.queue')" position="left"-->
<!--              @click="$store.commit('toggleAll', 'queues');"><i class="fa-duotone fa-check"></i></button>-->

<!--    </template>-->
    <template v-if="$route.matched[0].name === 'Documents'">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('toggle.documents')" position="left"
              @click="$store.commit('toggleAll', 'documents');"><i class="fa-duotone fa-check"></i></button>

    </template>
    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('toggle.customers')" position="left"
              @click="$store.commit('toggleAll', 'customers');"><i class="fa-duotone fa-check"></i></button>

    </template>
    <template v-if="$route.matched[0].name === 'Events' ">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('toggle.events')" position="left"
              @click="$store.commit('toggleAll', 'events');"><i class="fa-duotone fa-check"></i></button>

    </template>
  </template>
</template>
  <script>
    import Edit from "@/routing/core/Customers/Edit.vue";
    import Details from "@/routing/core/Products/Details.vue";
    import DetailsD from "@/routing/core/Drafts/Details.vue";
    import {mapGetters} from "vuex";
    import EditEmployee from "@/routing/core/Employees/Details.vue";
    import DetailsL from "@/routing/core/Legals/Details.vue";
    import DetailsS from "@/routing/core/Sepa/Details.vue";

    export default {
      name: "toggleall",
      data() {
        return {
        };
      },
      components: {},

      methods: {

      }
    }
  </script>

