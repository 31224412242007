<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs.categoriesEmployee.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
    <Sort @sort="resortOrder($event)" />
      <div  class="draft-item" v-for="draft in getConfigs.categoriesEmployee"
           :key="draft.id">
        <Headline :draft="draft"/>

      </div>
    <router-link to="/config/employee" class="mt-5 mb-5" style="display: block;font-size: 14px;color: #3B8130;"><i class="fa-duotone fa-chevron-left"></i> {{$t('nav.back')}}</router-link>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Details from "@/routing/core/Config/Employee/CategoriesEmployee/Details.vue";
import Headline from "@/routing/core/Config/Employee/CategoriesEmployee/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Employee/CategoriesEmployee/Layouts/Sort.vue";
import $ from "jquery";

export default {
  name: "YearPlanList",
  computed: {
    ...mapGetters(["getEmployees","getConfigs"])
  },

  components: {
    Sort,
    Headline,
    Details,
  },
  methods: {

    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
