<template>
  <nav
    class="navbarTop navbar"
    id="navbarBlur"
  >
    <div class="row " style="flex-grow: 1;">
      <div class="col-12 col-md mb-2 mb-md-0"><searchbar /></div>

      <div class="col-12 col-md ml-auto" style="text-align: right;">
        <restartCronjob />
        <Widget />
        <Import />
        <deleteSelected />
        <archiveSelected />
        <exportSelected />
        <reportSelected v-if="$route && $route.matched && $route.matched[0] && $route.matched[0].name && $route.matched[0].name === 'customers'"/>
        <mailtoSelected />
<!--        <mergeSelected />-->
        <toggleAll />

        <Timer />
        <plusbutton />
        <UserBar />

      </div>

    </div>




  </nav>
</template>
<script>
import {mapActions} from "vuex";
import plusbutton from "../../filter/components/plusbutton.vue";
import searchbar from "../../filter/components/searchbar.vue";
import toggleAll from "../../filter/components/toggleall.vue";
import exportSelected from "../../filter/components/exportSelected.vue";
import reportSelected from "../../filter/components/reportSelected.vue";
import mergeSelected from "../../filter/components/mergeSelected.vue";
import deleteSelected from "../../filter/components/deleteSelected.vue";
import archiveSelected from "../../filter/components/archiveSelected.vue";
import Timer from "@/routing/core/Tasks/Layouts/Timer.vue";
import UserBar from "@/routing/core/User/UserBar.vue";
import mailtoSelected from "../../filter/components/mailtoSelected.vue";
import Import from "../../filter/components/import.vue";
import Widget from "../../filter/components/widget.vue";
import restartCronjob from "../../filter/components/restartCronjob.vue";
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false
    };
  },
  components: {plusbutton, searchbar, toggleAll, Timer, UserBar, exportSelected, deleteSelected, mergeSelected, mailtoSelected, Import, Widget, reportSelected, restartCronjob, archiveSelected},
  methods: {
    ...mapActions(["logout"]),
  },

};
</script>
