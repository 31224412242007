<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <h6 class="editLine">Anmeldungen</h6>
      <div class="btn-group float-end">
        <button @click="aboPopup = true" class="btn btn-outline-primary btn-sm"><i
          class="fa-duotone fa-user-gear"></i> {{ subscriptions.length }} Abonnenten
        </button>
        <button @click="addMemberPopup = true" class="btn btn-outline-primary btn-sm"><i
          class="fa-duotone fa-user-plus"></i> Teilnehmer hinzufügen
        </button>
        <!--import button plz-->
        <button @click="importCsvPopup = true" class="btn btn-outline-secondary btn-sm"><i
          class="fa-duotone fa-file-import"></i> CSV Importieren
        </button>
        <button @click="exportMembers" class="btn btn-outline-secondary btn-sm"><i
          class="fa-duotone fa-file-export"></i> CSV Exportieren
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="registrations" v-if="$route.params.id">
        <div class="filters">
          <div class="filter">
            <label for="filter_search">Suche</label>
            <input type="text" id="filter_search" class="form-control small" @keyup.enter="getRegistrations"
                   v-model="filter_search">
          </div>
          <div class="filter">
            <label for="filter_state">Status</label>
            <select name="filter_sketchdates_state" @change="getRegistrations" class="small"
                    id="filter_sketchdates_state" v-model="filter_sketchdates_state">
              <option selected="" value="">- Anmeldestatus (alle) -</option>
              <option value="aw">Angemeldet + Warteliste</option>
              <option value="a">- Angemeldet -</option>
              <option value="w">- Warteliste -</option>
              <option value="s">- Storniert -</option>
            </select>
          </div>
        </div>
        <table class="table-striped table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th class="center">Anzahl</th>
            <th>Datum</th>
            <th>Infos</th>
            <th>Kontakt</th>
            <th>Stornieren</th>
            <th>manuelle Änderungen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r in registrations">
            <td>
              {{ r.id }}
            </td>
            <td style="text-align: center;">
              <template v-if="r.state === '0'"><span :tooltip="r.formatted.state" style="color: #b21f28;"><i
                class="fa-duotone fa-xmark-circle"></i></span></template>
              <template v-if="r.state === '1'"><span :tooltip="r.formatted.state" style="color: #3B8130;"><i
                class="fa-duotone fa-check-circle"></i></span></template>
              <template v-if="r.state === '2'"><span :tooltip="r.formatted.state" style="color: #fd7e14;"><i
                class="fa-duotone fa-timer"></i></span></template>
              <template v-if="r.isWaitingToActive ==='yes'"><span tooltip="nachgerückt von der Warteliste"><i
                class="fa-duotone fa-user-check"></i></span></template>
            </td>
            <td class="center">
              {{ r.quota }}
            </td>
            <td>{{ $filters.formatDate(r.registration_date) }}</td>
            <td><small>{{ r.profile.suffix }} {{ r.profile.firstname }} {{ r.profile.lastname }}
              <template v-if="r.profile.street || r.profile.city"><br>{{ r.profile.street }} {{ r.profile.city }}
              </template>
            </small>
            </td>
            <td><small>{{ r.email }}
              <template v-if="r.profile.phone"><br>{{ r.profile.phone }}</template>
              <template v-if="r.profile.mobile"><br>{{ r.profile.mobile }}</template>
            </small></td>
            <td>
              <div class="btn-group">
                <button @click="stornoMember(r.id,'stornoData')" v-if="r.state === '1'"
                        class="btn btn-outline-danger btn-sm" tooltip="Kostenpflichtig stornieren (no-show)"><i
                  class="fa-duotone fa-xmark-circle"></i> Stornorechnung schicken
                </button>
                <button @click="stornoMember(r.id,'stornoDataWithoutInvoice')" v-if="r.state === '1' || r.state === '2'"
                        class="btn btn-outline-secondary btn-sm" tooltip="kostenlos stornieren"><i
                  class="fa-duotone fa-eye-slash"></i> Keine Stornorechnung
                </button>
              </div>
            </td>
            <td>
              <select v-model="r.state" id="" class="form-select small" @change="quickChangeState(r)">
                <option value="1">Angemeldet</option>
                <option value="2">Warteliste</option>
                <option value="0">Storniert</option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
        <Modal v-if="aboPopup">
          <div class="modal show " style="display: block;">
            <div class="modal-dialog modal-lg">
              <div class="modal-content" style="min-height: 80vh;">
                <div class="modal-header">
                  <button class="btn-close" @click="aboPopup = false"></button>
                </div>
                <div class="modal-body">
                  <table>
                    <thead>
                    <tr>
                      <th>Mitglied</th>
                      <th>Name</th>
                      <th>Vorname</th>
                      <th>E-Mail</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="s in subscriptions">
                      <td>{{ s.c_customer_id }}</td>
                      <td>{{ s.c_lastname }}</td>
                      <td>{{ s.c_firstname }}</td>
                      <td>{{ s.c_email }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal v-if="addMemberPopup">
          <div class="modal show " style="display: block;">
            <div class="modal-dialog modal-lg">
              <div class="modal-content" style="min-height: 80vh;">
                <div class="modal-header">
                  <button class="btn-close" @click="addMemberPopup = false"></button>
                </div>
                <div class="modal-body">
                  <customersearch v-model="customer"/>
                  <button class="btn btn-outline-primary" @click="addMember" v-if="customer && customer.id"><i
                    class="fa-duotone fa-plus"></i> Hinzufügen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal v-if="importCsvPopup">
          <div class="modal show " style="display: block;">
            <div class="modal-dialog modal-lg">
              <div class="modal-content"  >
                <div class="modal-header">
                  Mitgliedsnummer &amp; Lerneinheiten importieren
                  <button class="btn-close" @click="importCsvPopup = false"></button>
                </div>
                <div class="modal-body">
                  <div class="form-group" style="margin-bottom: 0;">
                    <label for="csvFile">CSV Datei</label>
                    <input type="file" class="form-control" id="csvFile" @change="importCsv">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div class="noId" v-else>
        <h3>Bitte wähle eine Veranstaltung aus</h3>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: 'Registrations',
  components: {customersearch},
  data() {
    return {
      registrations: [],
      subscriptions: [],
      customer: null,
      addMemberPopup: false,
      importCsvPopup: false,
      aboPopup: false,
      filter_sketchdates_state: '',
      filter_search: '',
    }
  },
  computed: {
    ...mapGetters(["modalsList", "eventActive"])
  },
  methods: {
    stornoMember($regId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let payload = {
        activeDate: this.$route.params.id,
        activeSubDate: null,
        reg: $regId,
        type: type,
      }
      axios.post(this.$store.state.apiUrl + "date", payload, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getRegistrations();
      }).catch((error) => {
        // display error in toast
        this.$store.dispatch("handleApiResponse", error.response);
        this.getRegistrations();
      });
    },
    quickChangeState(reg) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let payload = {
        activeDate: this.$route.params.id,
        activeSubDate: null,
        reg: reg.id,
        type: 'quickChangeState',
        state: reg.state,
      }
      axios.post(this.$store.state.apiUrl + "date", payload, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getRegistrations();
      }).catch((error) => {
        // display error in toast
        this.$store.dispatch("handleApiResponse", error.response);
        this.getRegistrations();
      });
      console.log(reg);
    },
    exportMembers() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const filters = '?filter_sketchdates_state=' + this.filter_sketchdates_state + '&filter_search=' + this.filter_search;
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/registrations/export" + filters, {headers: headers})
        .then((response) => {
          const file = response.data.result.data;
          const linkSource = 'data:text/csv;base64,' + file;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', 'export_' + this.eventActive.title + '.csv');
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    importCsv(e) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // check if file is csv file
      if (e.target.files[0].type !== 'text/csv') {
        this.$store.dispatch('toastError', this.$t("Falscher Dateityp. Bitte nur CSV Dateien hochladen."));
        return;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      axios.post(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/importLe", formData, {headers: headers})
        .then((response) => {
          this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
          this.getRegistrations();
          this.importCsvPopup = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRegistrations() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const filters = '?filter_sketchdates_state=' + this.filter_sketchdates_state + '&filter_search=' + this.filter_search;
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/registrations" + filters, {headers: headers})
        .then((response) => {
          this.registrations = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/subscriptions", {headers: headers})
        .then((response) => {
          this.subscriptions = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMember() {
      const payload = {}
      payload.dateId = this.$route.params.id;
      payload.sendMail = 'yes';
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "customer/" + this.customer.id + "/addEvent", payload, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getRegistrations();
        this.addMemberPopup = false;
      }).catch((error) => {
        // display error in toast
        this.$store.dispatch("handleApiResponse", error.response);
        this.getRegistrations();
      });
    }
  },
  created() {
    this.getRegistrations();
  },
}
</script>
<style>
</style>
