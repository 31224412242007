<template>
  <div class="card-body product-list">

    <p class="text-sm text-secondary" v-if="!productList || productList.data === 0">Es wurden keine Produkte
      gefunden.</p>




    <Details  v-if="productActive && productActive.id" :product="productActive" />
    <Sort @sort="resortOrder($event)" />

      <div  class="product-item h-25" v-for="product in productList.data"
           :key="product.id">
        <Headline :product="product"/>
      </div>

    <pagination list="products" listName="productList" filterName="productsFilter"  />

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Details from "@/routing/core/Products/Details";
import $ from "jquery";
import Headline from "@/routing/core/Products/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Products/Layouts/Sort.vue";

export default {
  name: "ProductsList",
  computed: {
    ...mapGetters(["getEmployees", "productList", "productActive"])
  },
  data() {
    return {
      selectedProduct: null,
      activeProduct: null,
      productCategories: [],
      filter_query: '',
      filter_search: '',
      filter_category_id: '',
      filter_state: '',
    };
  },
  components: {
    Sort,
    Pagination,
    Headline,
    Details,
  },
  methods: {
    getProducts(){
      this.$store.dispatch("productsFilter");

    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.products.ordering.value = payload.type;
        this.$store.state.filters.products.direction.value = payload.direction;
      } else {
        this.$store.state.filters.products.ordering.value = payload.type
      }
      this.getProducts();
    },
  },
  created() {
    this.activeProduct = this.$route.params.id;
    if (this.activeProduct) {
      this.$store.dispatch("setActiveProduct", this.activeProduct);
    } else {
      this.getProducts();
    }
  },
  watch: {
    // watch router params id
    '$route': function () {
      if (!this.$route.params.id) {
        this.activeProduct = null;
        this.$store.dispatch("setActiveProduct", null);
      }
    },


  }
};
</script>
