<template>
  <div class="containerInline">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="customerData">
          <h4>Profildaten</h4>
          <div class="row">
            <div class="xlarge-4 xlarge-push-8 large-push-8 large-4 medium-12 small-12 columns">
              <div style="text-align: center" v-if="customer.profile_image">
                <div class="row">
                  <div class="col-12"><img :src="'data:'+customer.profile_mimetype+';base64,'+customer.profile_image" class="profileImage"/></div>
                  <div class="col-12"><button class="btn btn-outline-danger mt-2 mb-2" @click="deleteAvatar()"><i
                    class="fa fa-duotone fa-trash"></i> Bild entfernen
                  </button></div>
                </div>


              </div>
            </div>
            <div class="xlarge-8 xlarge-pull-4 large-pull-4 large-8 medium-12 small-12 columns">
              <div class="table-responsive">
                <table class="profileData">
                  <tbody>
                  <tr>
                    <td style="width: 170px;" class="align-top  d-none d-lg-block">Name</td>
                    <td class="align-top">{{ customer.c_firstname }} {{ customer.c_lastname }}</td>
                  </tr>
                  <tr>
                    <td class="align-top d-none d-lg-block ">Anschrift</td>
                    <td class="align-top">{{ customer.c_street }}<br>{{ customer.c_zipcode }} {{ customer.c_city }}</td>
                  </tr>
                  <tr>
                    <td class="align-top d-none d-lg-block ">Geburtsdatum</td>
                    <td class="align-top">{{ $filters.formatDate(customer.c_birthday) }}</td>
                  </tr>
                  <tr>
                    <td class="align-top d-none d-lg-block ">Geburtsort</td>
                    <td class="align-top">{{ customer.birthday_city }}</td>
                  </tr>
                  <tr>
                    <td class="align-top d-none d-lg-block ">Email</td>
                    <td class="align-top"> {{ customer.c_email }}</td>
                  </tr>
                  <tr>
                    <td class="align-top d-none d-lg-block ">Handynummer</td>
                    <td class="align-top"> {{ customer.c_handy }}</td>
                  </tr></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="customerData mt-4" v-if="customer.custom_fields">
          <h4>Aktuelle Trainerstation</h4>
          <div class="table-responsive">
            <table class="w100">
              <tbody>
              <tr>
                <td style="width: 170px" class="align-top  d-none d-lg-block ">Mannschaft</td>
                <td class="align-top">{{ customer.custom_fields.mannschaft }}</td>
              </tr>
              <tr>
                <td class="align-top d-none d-lg-block ">Liga</td>
                <td class="align-top">{{ customer.custom_fields.liga }}</td>
              </tr>
              <tr>
                <td class="align-top d-none d-lg-block ">Altersbereich</td>
                <td class="align-top">{{ customer.custom_fields.altersbereich }}</td>
              </tr>
              <tr>
                <td class="align-top d-none d-lg-block ">Aufstieg</td>
                <td class="align-top">
                  <template v-if="customer.custom_fields.aufstieg"><strong>Ja</strong></template>
                  <template v-else>Nein</template>
                </td>
              </tr>
              <template v-if="customer.custom_fields.aufstieg">


              <tr>
                <td class="align-top d-none d-lg-block ">Aufstieg von Liga</td>
                <td class="align-top">{{ customer.custom_fields.aufstiegVonLiga }}</td>
              </tr>
              <tr>
                <td class="align-top d-none d-lg-block ">Aufstieg in Liga</td>
                <td class="align-top">{{ customer.custom_fields.aufstiegInLiga }}</td>
              </tr>
              <tr>
                <td class="align-top d-none d-lg-block ">Aufstieg Verein</td>
                <td class="align-top">{{ customer.custom_fields.aufstiegVerein }}</td>
              </tr>
              <tr>
                <td class="align-top d-none d-lg-block ">Aufstieg Zeitpunkt</td>
                <td class="align-top">{{ $filters.formatDate(customer.custom_fields.aufstiegWann) }}</td>
              </tr>
              </template></tbody>
            </table>
          </div>

        </div>

        <!--      <router-link :to="{name:'profile'}" class="btn btn-primary mb-4">Profil bearbeiten</router-link>-->
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="customerData counterData mb-3" v-if="counters && options">
          <h4>Punktzahlen</h4>
          <div class="inline">
            <table class="table">
              <tr>
                <td style="padding:0; font-weight: bold" class="align-top">Titel</td>
                <td style="padding:0; font-weight: bold" class="align-top">Bestätigte Punkte</td>
                <td style="padding:0; font-weight: bold" class="align-top">Eingereichte Punkte</td>
              </tr>
            </table>
          </div>
          <div class="inline">
            <table class="table">
              <tr v-if="options.vereinstaetigkeit && options.vereinstaetigkeit.infos">
                <td class="align-top">{{ options.vereinstaetigkeit.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.vereinstaetigkeit">{{ counters.vereinstaetigkeit.groupSum }}</template>
                  <template v-else>0</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.vereinstaetigkeit && counters.vereinstaetigkeit.oldPoints">{{ counters.vereinstaetigkeit.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <div class="inline">
            <table class="table" v-if="options.verbandstaetigkeit">
              <tr>
                <td class="align-top">{{ options.verbandstaetigkeit.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.verbandstaetigkeit">{{ counters.verbandstaetigkeit.groupSum }}</template>
                  <template v-else>0</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.verbandstaetigkeit && counters.verbandstaetigkeit.oldPoints">{{ counters.verbandstaetigkeit.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <div class="inline">
            <table class="table" v-if="options.internationaltrainer">
              <tr>
                <td class="align-top">{{ options.internationaltrainer.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.internationaltrainer">{{ counters.internationaltrainer.groupSum }}</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.internationaltrainer && counters.internationaltrainer.oldPoints">{{ counters.internationaltrainer.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <hr>
          <div class="inline" v-if="counters.vereinsspieler">
            <table class="table">
              <tr>
                <td class="align-top">Spieler*in Erfahrung: nationale Ebene</td>
                <td class="align-top"> {{ counters.vereinsspieler.groupSum + counters.nationalspieler.groupSum }}</td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.vereinsspieler && counters.vereinsspieler.oldPoints">{{ counters.vereinsspieler.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <div class="inline">
            <table class="table" v-if="options.internationalspieler">
              <tr>
                <td class="align-top">{{ options.internationalspieler.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.internationalspieler">{{ counters.internationalspieler.groupSum }}</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.internationalspieler && counters.internationalspieler.oldPoints">{{ counters.internationalspieler.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <div class="inline" v-if="counters.groupSums !== counters.complete">
            <table class="table">
              <tr>
                <td class="align-top">Nicht anrechenbare Spieler*in Erfahrung (gemäß AO)</td>
                <td class="align-top">{{ counters.diffSum }}</td>
                <td class="align-top"><span class="oldValues"><template>0</template></span></td>
              </tr>
            </table>
          </div>
          <hr>
          <div class="inline">
            <table class="table" v-if="options.ausbildung">
              <tr>
                <td class="align-top">{{ options.ausbildung.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.ausbildung">{{ counters.ausbildung.groupSum }}</template>
                  <template v-else>0</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.ausbildung && counters.ausbildung.oldPoints">{{ counters.ausbildung.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <div class="inline" v-if="options.trainerausbildung">
            <table class="table">
              <tr>
                <td class="align-top">{{ options.trainerausbildung.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.trainerausbildung">{{ counters.trainerausbildung.groupSum }}</template>
                  <template v-else>0</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.trainerausbildung && counters.trainerausbildung.oldPoints">{{ counters.trainerausbildung.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <div class="inline">
            <table class="table" v-if="options.weiterbildung">
              <tr>
                <td class="align-top">{{ options.weiterbildung.infos.title }}</td>
                <td class="align-top">
                  <template v-if="counters.weiterbildung">{{ counters.weiterbildung.points }}</template>
                  <template v-else>0</template>
                </td>
                <td class="align-top"><span class="oldValues"><template
                  v-if="counters.weiterbildung && counters.weiterbildung.oldPoints">{{ counters.weiterbildung.oldPoints }}</template><template
                  v-else>0</template> </span></td>
              </tr>
            </table>
          </div>
          <hr>
          <div class="complete">
            <table class="table">
              <tbody>
              <tr>
                <th class="align-top">Gesamtpunktzahl</th>
                <td class="align-top"><strong style="padding: 0">{{ counters.complete }}</strong></td>
                <td class="align-top"><strong style="padding: 0">{{ counters.oldComplete }}</strong></td>
              </tr></tbody>
            </table>
          </div>
        </div>
        <div class="customerData mt-4" v-if="customer.custom_fields && customer.custom_fields.language">
          <h4>Sprachkenntnisse</h4>
          <div class="table-responsive">
            <table class="w100">
              <tbody>
              <tr v-for="lang in customer.custom_fields.language.other">
                <td class="align-top ">{{ lang.language }}</td>
                <td class="align-top">{{ lang.level }}</td>
              </tr></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="customerData">
        <h4>
           Bisherige Lizenzstufen
        </h4>
          <Customer mode="viewLicense" :customer="customer" :licenses="licenses" @refresh-after-save="refreshAfterSave()" />
          <p>Darstellung in rot ab {{$filters.formatDate(searchDate)}}</p>
          <template v-if="licenses && licenses.length > 0">

          <table class="table-responsive">
            <thead>
            <tr>
              <th style="border: 0;">Upload am</th>
              <th style="border: 0;">Lizenz</th>
              <th style="border: 0;">Ausbildungsstart</th>
              <th style="border: 0;">Gültig bis</th>
              <th style="border: 0;text-align: center;">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="(dateBiggerThanDate(license.crm_created_date)) ? 'bg-warning' : ''" v-for="license in licenses">
              <td>{{ $filters.formatDate(license.crm_created_date) }}</td>
              <td>{{ license._licenseTitle }} {{ license.no }}</td>
              <td>{{ $filters.formatDate(license.start_from) }}</td>
              <td>{{$filters.formatDate(license.valid_to)}}</td>
              <td style="text-align: center;">
                <template v-if="license.verified === 'verified'"><span style="color: green;" tooltip="Verifiziert"><i
                  class="fa-duotone fa-check-circle"></i></span></template>
                <template v-if="license.verified === 'waiting'"><span style="color: orange;" tooltip="Wartend"><i
                  class="fa-duotone fa-question-circle"></i></span></template>
                <template v-if="license.verified === 'denied'"><span style="color: darkred;" tooltip="Abgelehnt"><i
                  class="fa-duotone fa-xmark-circle"></i></span></template>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
        <template v-else>KEINE VORHANDEN</template>
        </div>
        <div class="customerData">
        <h4>
          Bisherige Nachweise
        </h4>

        <template v-if="attests && attests.length > 0">

          <p>Darstellung in rot ab {{$filters.formatDate(searchDate)}}</p>


          <div class="pluginWrap">
            <div class="plugin-item"  :class="(dateBiggerThanDate(attest.crm_created_date)) ? 'bg-warning' : ''"  v-for="attest in attests">


              <div class="lineitem l-date" style="font-size: 12px">{{ $filters.formatDate(attest.valid_from) }}
              </div>
              <div class="lineitem l-date" style="font-size: 12px">{{ $filters.formatDate(attest.crm_created_date) }}
              </div>
              <div class="lineitem l-state">
                  <span class="stateIcon">
                    <template v-if="attest.verified ==='verified_waiting'">
                      <span tooltip="Akzeptiert #1" style="color: yellowgreen;"><i class="fa-duotone fa-check-circle"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='verified_complete'">
                      <span tooltip="Akzeptiert #2" style="color: green;"><i class="fa-duotone fa-check-circle"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='waiting'">
                      <span tooltip="Wartend" style="color: orange;"><i class="fa-duotone fa-clock"></i></span>
                    </template>
                    <template v-else>
                      <span tooltip="Abgelehnt" style="color: darkred;"><i
                        class="fa-duotone fa-xmark-circle"></i></span></template>
                     </span>
              </div>
              <div class="lineitem l-title" style="font-size: 12px">
                {{ attest._attestTitle }}
              </div>
              <div class="lineitem l-title" style="font-size: 12px">
                {{ attest._validState }}
              </div>
              <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">
                {{ attest._customerName }}
              </div>
              <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">

                {{ attest._verifiedWaitingBy }}<br>
                {{ $filters.formatDateTime(attest._verifiedWaitingDate) }}
              </div>
              <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">

                {{ attest._verifiedBy }}<br>
                {{ $filters.formatDateTime(attest._verifiedDate) }}
              </div>


            </div>

          </div>
        </template>
        <template v-else>KEINE VORHANDEN</template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Customer from "@/routing/plugins/dfb/Customer.vue";
export default {
  name: 'DFBProfile',
  props: {
    request: {},
    customer: {},
    counters: {},
    options: {},
    licenses: [],
    attests: []
  },
  components: {
    Customer
  },
  data() {
    return {

      searchDate: this.request.published_to,
      complete: 0
    }
  },
  emits: ['refreshActiveRequest'],
  created() {
    let a;
    let c;
    for (a in this.counters) {
      c = this.counters[a];
      this.complete += c.points;
    }
    // searchDate + 1day
    this.searchDate = new Date(this.searchDate);
    this.searchDate.setDate(this.searchDate.getDate() + 1);
  },
  methods: {
    dateBiggerThanDate(date) {
      return new Date(date) > new Date(this.searchDate);
    },
    deleteAvatar() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.getters.getApiUrl + 'customer/' + this.customer.id, {
        profile_image: '',
        profile_mimetype: ''
      }, {headers}).then(function (response) {
        let shortReport = response.data.result;
        if (shortReport.success === true) {
          this.$emit('refreshActiveRequest');
          this.$store.dispatch("toastSuccess", "Avatar gelöscht");
        } else {
          this.$store.dispatch("toastError", "Avatar konnte nicht gelöscht werden");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    refreshAfterSave() {
      this.$emit('refreshActiveRequest');
    }
  }
}
</script>
<style lang="scss" scoped>
th, td {
  border-bottom-color: red;
}

.customerData .table-responsive td, .customerData .table-responsive th {
  font-size: 12px;
  text-align: left;
  /* max-width: 100%; */
  text-overflow: ellipsis;
}
.task {
  border-bottom: 5px solid #ffffff
}
.inline table.table {
  margin-bottom: 0px;
}

.inline table {
  width: 100%;
}

.complete table td, .inline table td {
  text-align: right;
  width: 24%;
  &:first-child {
    width: 52%;
    text-align: left;
  }
  &:last-child {
    color: #ccc;
  }
}
.customerData {
  background: #f7f7f7;
  padding: 6px;
  margin-bottom: 24px;
}
.profileImage {
  max-height: 200px;
  max-width: 100%;
}

table.profileData {
  float: left;
  max-width: 100%;
  width: 400px;
}
.plugin-item.bg-warning, tr.bg-warning td {
  background-color: #f8d7da !important;
}
</style>
