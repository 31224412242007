<template>
  <div class="appView greenView">
    <div class="row">
      <div class="col-12">
        <button class="btn btn-outline-primary btn-sm float-end" style="margin-top: -55px; transform: translateX(-103px)"
                v-if="infoLine === 'time'"
                @click="setNewTime" tooltip="Zeit eintragen" position="left"><i class="fa-duotone fa-plus"></i></button>
      </div>
    </div>
    <div class="container-fluid ">
      <div class="row">
        <div class="col-12">
          <ul class="tabNav">
            <li @click="infoLine = 'time'" :class="(infoLine === 'time') ? 'active' : ''"><i
              class="fa-duotone fa-stopwatch"></i> Zeiterfassung
            </li>
            <li @click="infoLine = 'story'" :class="(infoLine === 'story') ? 'active' : ''"><i
              class="fa-duotone fa-file-binary"></i> Arbeitszeitkonto
            </li>
            <li @click="infoLine = 'tickets'" :class="(infoLine === 'tickets') ? 'active' : ''"><i
              class="fa-duotone fa-clipboard-list-check"></i> Zeitaufwand
            </li>
            <li @click="infoLine = 'invoices'" :class="(infoLine === 'invoices') ? 'active' : ''"><i
              class="fa-duotone fa-file-contract"></i> Abrechnung
            </li>
          </ul>
        </div>
      </div>
      <div class="row mb-3" v-if="infoLine !== 'tickets' && infoLine !== 'invoices'">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-12">
                  <div class="form-group">
                    <label for="employee">Mitarbeiter</label>
                    <select id="employee" class="form-select small" v-model="filter_owner_id" v-if="getEmployees"
                            @change="filter">
                      <option v-for="employee in getEmployees" :value="employee.id" v-bind:key="employee.id">
                        {{ employee.e_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-4 col-12">
                  <div class="form-group">
                    <label for="month">Monat</label>
                    <select id="month" class="form-select small" v-model="filter_month" @change="filter">
                      <option value="01">Januar</option>
                      <option value="02">Februar</option>
                      <option value="03">März</option>
                      <option value="04">April</option>
                      <option value="05">Mai</option>
                      <option value="06">Juni</option>
                      <option value="07">Juli</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Dezember</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-4 col-12">
                  <div class="form-group">
                    <label for="year">Jahr</label>
                    <select id="year" class="form-select small" v-model="filter_year" @change="filter">
                      <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="infoLine ==='time'">
        <div class="row">
          <div class="col-12" v-if="sums && selectedEmployee">
            <h2 style="text-transform: none;">
            <span class="badge badge-secondary me-2" tooltip="Ist-Zeit"><i
              class="fa-duotone fa-business-time"></i> {{ sums.sumInQuery }}</span>
              <span class="badge badge-secondary me-2" tooltip="Soll-Zeit"><i
                class="fa-duotone fa-business-time fa-swap-opacity"></i> {{ sums.sumInConfigs }}</span>
              <span class="badge badge-secondary me-2" tooltip="Differenz"><i class="fa-duotone fa-file-plus-minus"></i> {{
                  sums.sumDiff
                }}</span>
              <span class="badge badge-secondary me-2" tooltip="Dokumentierte Tage"><i
                class="fa-duotone fa-calendar-day"></i> {{ sums.sumInQueryDays }} </span>
              <span class="badge badge-secondary me-2" tooltip="Arbeitstage" style="text-transform: none;"
                    v-if="sums && sums.sumInQueryWorking && sums.sumInQueryWorking.countDays"><i class="fa-duotone fa-briefcase-blank"></i> {{
                  sums.sumInQueryWorking.countDays
                }} / {{ sums.monthlyDaysinConfig }} ({{ sums.sumInQueryWorking.countTime }} Stunden)</span>
              <span class="badge badge-secondary me-2" tooltip="Krankheitstage" style="text-transform: none;"
                    v-if="sums && sums.sumInQueryIllness"><i
                class="fa-duotone fa-notes-medical"></i> {{ sums.sumInQueryIllness.countDays }}</span>
              <span class="badge badge-secondary me-2" tooltip="Urlaubstage (genommen/verbleibend)"
                    style="text-transform: none;" v-if="sums && sums.sumInQueryHoliday && sums.sumInQueryHoliday.countDays"><i
                class="fa-duotone fa-island-tropical"></i> {{
                  sums.sumInQueryHoliday.countDays
                }} / {{ selectedEmployee.story.end_holiday_days }}</span>
            </h2>
            <h6>Aktueller Monat:
              <span class="badge badge-primary me-2" tooltip="Übrige Urlaubstage"><i
                class="fa-duotone fa-island-tropical"></i> {{ selectedEmployee.story.end_holiday_days }}</span>
              <span class="badge badge-primary me-2" tooltip="Überstunden aus Vormonat"><i
                class="fa-duotone fa-plus-circle"></i>
              <template v-if="selectedEmployee.story.begin_worktime === null"> 0</template>
              <template v-else>&nbsp;{{ selectedEmployee.story.begin_worktime }}</template>
              </span>
              <span class="badge badge-primary me-2" tooltip="Überstunden aktueller Monat"><i
                class="fa-duotone fa-plus-square"></i> {{ sums.completeDiff }}</span>
            </h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive p-0 stickyTable">
                <table class="table table-hover table-sm">
                  <thead>
                  <tr>
                    <th style="width: 100px;">Tag</th>
                    <th style="width: 100px;">Wochentag</th>
                    <th style="width: 100px; text-align: right;">Summe</th>
                    <th style="width: 30px;">&nbsp;</th>
                    <th>Grund</th>
                    <th>Typ</th>
                    <th style="width: 100px;"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="currentTimes.length  > 0 " v-for="currentTime in currentTimes" v-bind:key="currentTime.id"
                      :class="(formatWeekDay(currentTime.day) === 'Freitag') ? 'lastday': ''">
                    <td>{{ $filters.formatDate(currentTime.day) }}</td>
                    <td>{{ formatWeekDay(currentTime.day) }}</td>
                    <td style="text-align: right;">{{ currentTime.sum }}</td>
                    <td>&nbsp;</td>
                    <td>{{ currentTime.reason }}</td>
                    <td style="text-transform: capitalize;">{{ currentTime.working_type }}</td>
                    <td>
                      <button class="btn-sm btn-outline-primary btn me-3" @click="setEditTime(currentTime)"><i
                        class="fa-duotone fa-edit"></i></button>
                      <button class="btn-sm btn-outline-danger btn" @click="deleteTime(currentTime)"><i
                        class="fa-duotone fa-trash"></i></button>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="100%">
                      <template v-if="selectedEmployee"><p>Kein Eintrag gefunden.</p></template>
                      <template v-else>
                        <p class="mt-4 text-center">
                          <button class="btn btn-outline-primary btn-sm"
                                  @click="$router.push('/employee/'+filter_owner_id)">Arbeitszeitkonto anlegen.
                          </button>
                        </p>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
                  </div>
              </div>
            </div>
            <button class="btn btn-outline-primary mt-3 mb-3 me-2" @click="fillMonth"><i class="fa-duotone fa-file"></i>
              Monat automatisch füllen
            </button>
            <button class="btn btn-outline-danger mt-3 mb-3 me-2" @click="finishMonth"><i
              class="fa-duotone fa-file"></i> Monat festschreiben
            </button>
            <button class="btn btn-outline-danger mt-3 mb-3 me-2" @click="printMonth"><i class="fa-duotone fa-file"></i>
              Monat drucken
            </button>
          </div>
        </div>
      </template>
      <template v-if="infoLine ==='story'">
        <div class="row">
          <div class="col-12">
            <button @click="toggleEditTimeStory" class="btn btn-outline-primary btn-sm mb-3"><i
              class="fa-duotone fa-clock"></i> Zeit hinzufügen
            </button>
          </div>
        </div>
        <div class="row" v-if="showEditTimeStory">
          <div class="col-3">
            <div class="form-group">
              <label>Typ</label>
              <select v-model="storyEdit.type" class="form-select">
                <option value="worktime">
                  Arbeitszeit
                </option>
                <option value="holiday">Urlaub</option>
              </select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Stunden</label>
              <input type="number" v-model="storyEdit.hours" class="form-control" step="0.01"/>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>Grund</label>
              <input type="text" v-model="storyEdit.text" class="form-control"/>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label style="display: block;">&nbsp;</label>
              <button class="btn btn-outline-primary" @click="addStoryItem" style="min-height: 48px;
    margin-top: 4px;
    width: 48px;"><i class="fa-duotone fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card mb-3">
              <div class="card-body">
                <h6>Arbeitstage</h6>
                <div class="table-responsive p-0">
                <table class="table table-hover table-sm"
                       v-if="selectedEmployee && selectedEmployee.story && selectedEmployee.story.items">
                  <thead>
                  <tr>
                    <th style="width: 125px;">Tag</th>
                    <th>Beschreibung</th>
                    <th style="width: 170px;">Grund</th>
                    <th style="width: 100px;text-align: right; padding-right: 12px !important">Stunden</th>
                    <th style="width: 170px;">Zeitpunkt</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in selectedEmployee.story.items.worktime">
                    <td>{{ $filters.formatDate(item.period) }}</td>
                    <td>{{ item.text }}</td>
                    <td>{{ $t('time.' + item.reason) }}</td>
                    <td style="text-align: right; padding-right: 12px;">{{ item.hours }}</td>
                    <td>{{ $filters.formatDateTime(item.ts_created) }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="3" style="text-align: right;border-color: transparent;">&nbsp;</td>
                    <td style="text-align: right; padding-right: 12px;"><strong>{{
                        selectedEmployee.story.end_worktime
                      }}</strong></td>
                    <td style="border-color: transparent;">&nbsp;</td>
                  </tr>
                  </tfoot>
                </table></div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h6 class="mt-3">Urlaubstage</h6>
                <div class="table-responsive p-0">
                <table class="table table-hover table-sm"
                       v-if="selectedEmployee && selectedEmployee.story && selectedEmployee.story.items">
                  <thead>
                  <tr>
                    <th style="width: 125px;">Tag</th>
                    <th>Beschreibung</th>
                    <th style="width: 170px;">Grund</th>
                    <th style="width: 100px; text-align: right; padding-right: 12px !important">Stunden</th>
                    <th style="width: 170px;">Zeitpunkt</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in selectedEmployee.story.items.holiday">
                    <td>{{ $filters.formatDate(item.period) }}</td>
                    <td>{{ item.text }}</td>
                    <td>{{ $t('time.' + item.reason) }}</td>
                    <td style="text-align: right; padding-right: 12px;">{{ item.hours }}</td>
                    <td>{{ $filters.formatDateTime(item.ts_created) }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="3" style="text-align: right;border-color: transparent;">&nbsp;</td>
                    <td style="text-align: right; padding-right: 12px;"><strong>{{
                        selectedEmployee.story.end_holiday
                      }}</strong></td>
                    <td style="border-color: transparent;">&nbsp;</td>
                  </tr>
                  </tfoot>
                </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="infoLine === 'tickets'">
        <TicketTime/>
      </template>
      <template v-if="infoLine === 'invoices'">
        <TicketTimeInvoices />
      </template>

    </div>
  </div>
  <Modal v-if="showEditTime">
    <div class="modal-dialog modal-xl greenView">
      <div class="modal-content">
        <div class="card">
          <div class="card-header timesOverviewModal">
            <h6>
              <template v-if="editTime.id">
                Eintrag bearbeiten
              </template>
              <template v-else>
                Neuer Eintrag
              </template>
              <button type="button" class="btn btn-close float-end ms-4" @click="showEditTime = false"></button>
              <button type="button" class="btn btn-outline-primary btn-sm float-end" @click="updateTime(editTime)"><i
                class="fa-duotone fa-floppy-disk"></i> Speichern
              </button>
            </h6>
          </div>
          <div class="card-body" v-if="editTime">
            <div class="form-group mb-2">
              <Datepicker ref="pickDate" v-model="editTime.day" auto-apply model-type="yyyy-MM-dd"
                          format="dd.MM.yyyy" locale="de" :teleport="true" :enable-time-picker="false" cancel-text="X"
                          select-text="Wählen">
              </Datepicker>
            </div>
            <div class="form-group mb-2">
              <input type="time" class="form-control" ref="timeStart" v-model="editTime.from"/>
            </div>
            <div class="form-group mb-2">
              <input type="time" v-model="editTime.till" ref="timeEnd" class="form-control"/>
            </div>
            <div class="form-group mb-2">
              <label>Grund</label>
              <input type="text" class="form-control" v-model="editTime.reason">
            </div>
            <div class="form-group mb-2">
              <label>Summe</label>
              <input type="text" class="form-control" v-model="editTime.sum" readonly>
            </div>
            <div class="form-group mb-2">
              <label>Typ</label>
              <select class="form-select" v-model="editTime.working_type">
                <option value="normal">Arbeit</option>
                <option value="urlaub">Urlaub</option>
                <option value="krank">Krankheit</option>
                <option value="sonderurlaub">Sonderurlaub</option>
              </select>
            </div>
            <div class="form-group mb-2">
              <label>Mitarbeiter</label>
              <select class="form-select" v-model="editTime.e_id">
                <option v-for="employee in getEmployees" :value="employee.id" v-bind:key="employee.id">
                  {{ employee.e_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
import TicketTime from "./TicketTime";
import TicketTimeInvoices from "./TicketTimeInvoices";

export default {
  name: 'TimesOverview',
  data() {
    return {
      employees: {},
      currentTimes: {},
      selectedEmployee: null,
      selectedMonth: null,
      selectedYear: null,
      filter_query: '',
      filter_owner_id: '',
      filter_month: '',
      filter_year: '',
      editTime: {},
      showEditTime: false,
      showEditTimeStory: false,
      sums: {},
      infoLine: "time",
      storyEdit: {
        type: 'worktime',
        hours: 0,
        text: ''
      }
    }
  },
  components: {
    Datepicker, TicketTime, TicketTimeInvoices
  },
  methods: {
    getLocalEmployees() {
      this.employees = this.getEmployees;
    },
    setDefaults() {
      if (this.getUser && this.getUser._isEmployee) {
        this.filter_owner_id = this.getUser._isEmployee;
      }
      // get Current month like this: 01, 02, 03, ...
      let date = new Date();
      this.filter_month = ("0" + (date.getMonth() + 1)).slice(-2);
      this.filter_year = date.getFullYear();
    },
    formatWeekDay(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('de-DE', {weekday: 'long'});
    },
    setEditTime(time) {
      this.editTime = time;
      this.showEditTime = true;
    },
    toggleEditTimeStory() {
      this.showEditTimeStory = !this.showEditTimeStory;
    },
    filter() {
      this.filter_query = '';
      if (this.filter_owner_id) {
        this.filter_query += '?filter_owner=' + this.filter_owner_id;
      }
      if (this.filter_month) {
        this.filter_query += '&filter_month=' + this.filter_month;
      }
      if (this.filter_year) {
        this.filter_query += '&filter_year=' + this.filter_year;
      }
      this.getActiveEmployeeDetails();
      this.getCurrentTimes();
    },
    setNewTime() {
      this.editTime = {
        day: new Date(),
        from: '08:00',
        till: '16:00',
        reason: '',
        sum: 8,
        owner_id: this.filter_owner_id
      };
      this.showEditTime = true;
    },
    addStoryItem() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "employee/" + this.filter_owner_id + '/timeStory', this.storyEdit, {headers}).then((r) => {
        VueInner.$store.dispatch('toastSuccess', 'Eintrag wurde erfolgreich gespeichert');
        VueInner.getActiveEmployeeDetails();
        VueInner.storyEdit = {
          type: 'worktime',
          hours: 0,
          text: ''
        }
        VueInner.showEditTimeStory = false;
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    fillMonth() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + this.filter_owner_id + '/fillTime' + this.filter_query, {headers}).then((r) => {
        VueInner.$store.dispatch('toastSuccess', 'Gewählter Monat wurde ausgefüllt.');
        VueInner.getCurrentTimes();
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    finishMonth() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + this.filter_owner_id + '/finishTime' + this.filter_query, {headers}).then((r) => {
        VueInner.$store.dispatch('toastSuccess', 'Gewählter Monat wurde festgeschrieben.');
        VueInner.getCurrentTimes();
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    printMonth() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "employee/" + this.filter_owner_id + '/printTimePDF' + this.filter_query, {headers}).then((r) => {
        const linkSource = 'data:application/pdf;base64,' + r.data.result.data;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'timesheet_' + this.filter_owner_id + '.pdf');
        window.document.body.appendChild(a);
        a.click();
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    updateTime(currentTime) {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (this.editTime.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "time/" + this.editTime.id, this.editTime, {headers}).then(() => {
          VueInner.$store.dispatch('toastSuccess', 'Zeiteintrag wurde erfolgreich bearbeitet');
          VueInner.getCurrentTimes();
          this.showEditTime = false;
        }).catch((r) => {
          VueInner.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "time", this.editTime, {headers}).then(() => {
          VueInner.$store.dispatch('toastSuccess', 'Zeiteintrag wurde erfolgreich gespeichert');
          VueInner.getCurrentTimes();
          this.showEditTime = false;
        }).catch((r) => {
          VueInner.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteTime(currentTime) {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "time/" + currentTime.id, {headers}).then(() => {
        VueInner.$store.dispatch('toastSuccess', 'Zeiteintrag wurde erfolgreich gelöscht');
        VueInner.getCurrentTimes();
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    getCurrentTimes() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "time/search" + this.filter_query, {headers}).then((r) => {
        VueInner.currentTimes = r.data.result.data;
        VueInner.sums = r.data.result.sums;
        // To-do get selectedEmployee depending on month and year, remove function when done
      });
    },
    timeDiff(from, till) {
      let fromTime = new Date("1970-01-01 " + from);
      let tillTime = new Date("1970-01-01 " + till);
      let diff = tillTime.getTime() - fromTime.getTime();
      let hours = Math.floor(diff / 1000 / 60 / 60);
      let minutes = Math.floor(diff / 1000 / 60) - (hours * 60);
      // not minutes but hours decimal point value
      minutes = minutes / 60;
      return hours + minutes;
    },
    getActiveEmployeeDetails() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "employee/" + this.filter_owner_id + this.filter_query, {headers}).then((r) => {
        VueInner.selectedEmployee = r.data.result.timeStory;
      });
    }
  },
  created() {
    this.getLocalEmployees();
    this.setDefaults();
    this.filter();
  },
  computed: {
    ...mapGetters(["getPlugins", "getUser", "getEmployees"]),
    years() {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2015}, (value, index) => 2016 + index)
    },
  },
  watch: {
    'editTime.from': function (val) {
      this.editTime.sum = this.timeDiff(this.editTime.from, this.editTime.till).toFixed(2);
    },
    'editTime.till': function (val) {
      this.editTime.sum = this.timeDiff(this.editTime.from, this.editTime.till).toFixed(2);
    }
  }
}
</script>

