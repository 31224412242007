<template>
  <div class="modalsInline">
    <Modal class="inputBlock mb-3" v-if="showInput === 1">
      <div class="container" v-if="options && options.vereinstaetigkeit">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4" v-if="options.vereinstaetigkeit.infos">{{ options.vereinstaetigkeit.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.vereinstaetigkeit.infos.description"></div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <input type="text" v-model="values.verein" class="form-control" placeholder="Verein">
                <label for="floatingInput">Verein</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="values.altersklasse" class="form-control"
                       placeholder="Altersklasse">
                <label for="floatingInput">Altersklasse</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="values.liga" class="form-control" placeholder="Liga">
                <label for="floatingInput">Liga</label>
              </div>
              <div class="form-floating mb-3">
                <input type="date" :max="$store.state.maxDateValue" v-model="values.dateStart"
                       class="form-control" placeholder="Beginn">
                <label for="floatingInput">Beginn</label>
              </div>
              <div class="form-floating mb-3">
                <input type="date" :max="$store.state.maxDateValue" v-model="values.dateEnd"
                       class="form-control" placeholder="Ende">
                <label for="floatingInput">Ende</label>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="values.saisons" class="form-control" placeholder="Saisons"
                       min="0" step="0.50">
                <label for="floatingInput">Saisons</label>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.vereinstaetigkeit.options" v-bind:key="key">
                    <input :value="option.id" v-model="values.answer_id" type="radio"
                           class="btn-check" :id="'option'+key"
                           autocomplete="off">
                    <label class="btn btn-outline-secondary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="values.oldSubmit" :values="values.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('values')" v-model="activeComment" name="" id=""
                            class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('values');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="values.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="values.answer_id"
                      @click="postValues('vereinstaetigkeit', values); showInput = 0;" type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="values.answer_id"
                      @click="putValues('vereinstaetigkeit', values); showInput = 0;" type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="values.answer_id"
                      @click="deleteValues('vereinstaetigkeit', values); showInput = 0;" type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="inputBlock mb-3" v-if="showInput1 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.verbandstaetigkeit.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.verbandstaetigkeit.infos.description"></div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesNat.verband" class="form-control"
                       placeholder="Verband">
                <label for="floatingInput">Verband</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesNat.altersklasse" class="form-control"
                       placeholder="Altersklasse">
                <label for="floatingInput">Altersklasse</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesNat.liga" class="form-control" placeholder="Liga">
                <label for="floatingInput">Liga</label>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-floating mb-3">
                    <input type="date" :max="$store.state.maxDateValue"
                           v-model="valuesNat.dateStart" class="form-control" placeholder="Beginn">
                    <label>Beginn</label>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-floating mb-3">
                    <input type="date" :max="$store.state.maxDateValue" v-model="valuesNat.dateEnd"
                           class="form-control" placeholder="Ende">
                    <label>Ende</label>
                  </div>
                </div>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesNat.saisons" class="form-control"
                       placeholder="Saisons" min="0" step="0.50">
                <label for="floatingInput">Saisons</label>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.verbandstaetigkeit.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesNat.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesNat.oldSubmit" :values="valuesNat.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesNat')" v-model="activeComment" name=""
                            id="" class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesNat');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesNat.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesNat.answer_id"
                      @click="postValues('verbandstaetigkeit', valuesNat); showInput1 = 0;" type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesNat.answer_id"
                      @click="putValues('verbandstaetigkeit', valuesNat); showInput1 = 0;" type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesNat.answer_id"
                      @click="deleteValues('verbandstaetigkeit', valuesNat); showInput1 = 0;"
                      type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="inputBlock mb-3" v-if="showInput2 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.internationaltrainer.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.internationaltrainer.infos.description"></div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesI.verband" class="form-control" placeholder="Verband">
                <label for="floatingInput">Verband</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesI.altersklasse" class="form-control"
                       placeholder="Altersklasse">
                <label for="floatingInput">Altersklasse</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesI.liga" class="form-control" placeholder="Liga">
                <label for="floatingInput">Liga</label>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-floating mb-3">
                    <input type="date" :max="$store.state.maxDateValue" v-model="valuesI.dateStart"
                           class="form-control" placeholder="Beginn">
                    <label>Beginn</label>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-floating mb-3">
                    <input type="date" :max="$store.state.maxDateValue" v-model="valuesI.dateEnd"
                           class="form-control" placeholder="Ende">
                    <label>Ende</label>
                  </div>
                </div>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesI.saisons" class="form-control"
                       placeholder="Saisons" min="0" step="0.50">
                <label for="floatingInput">Saisons</label>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesI.pointsToCalc" class="form-control"
                       placeholder="Punkte pro Saison" min="0" step="0.25">
                <label for="floatingInput">Punkte pro Saison</label>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.internationaltrainer.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesI.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesI.oldSubmit" :values="valuesI.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesI')" v-model="activeComment" name="" id=""
                            class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesI');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesI.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesI.answer_id"
                      @click="postValues('internationaltrainer', valuesI); showInput2 = 0;" type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesI.answer_id"
                      @click="putValues('internationaltrainer', valuesI); showInput2 = 0;" type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesI.answer_id"
                      @click="deleteValues('internationaltrainer', valuesI); showInput2 = 0;"
                      type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showInput5 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.ausbildung.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.ausbildung.infos.description"></div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesBildung.schwerpunkt" class="form-control"
                       placeholder="Schwerpunkt">
                <label for="floatingInput">Schwerpunkt</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesBildung.institution" class="form-control"
                       placeholder="Institution">
                <label for="floatingInput">Institution</label>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.ausbildung.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesBildung.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesBildung.oldSubmit" :values="valuesBildung.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesBildung')" v-model="activeComment" name=""
                            id="" class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesBildung');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesBildung.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesBildung.answer_id"
                      @click="postValues('ausbildung', valuesBildung); showInput5 = 0;" type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesBildung.answer_id"
                      @click="putValues('ausbildung', valuesBildung); showInput5 = 0;" type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesBildung.answer_id"
                      @click="deleteValues('ausbildung', valuesBildung); showInput5 = 0;" type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="showInputT === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.trainerausbildung.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.trainerausbildung.infos.description"></div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesT.schwerpunkt" class="form-control"
                       placeholder="Schwerpunkt">
                <label for="floatingInput">Schwerpunkt</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesT.institution" class="form-control"
                       placeholder="Institution">
                <label for="floatingInput">Institution</label>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.trainerausbildung.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesT.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesT.oldSubmit" :values="valuesT.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesT')" v-model="activeComment" name="" id=""
                            class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesT');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesT.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesT.answer_id"
                      @click="postValues('trainerausbildung', valuesT); showInputT = 0;" type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesT.answer_id"
                      @click="putValues('trainerausbildung', valuesT); showInputT = 0;" type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesT.answer_id"
                      @click="deleteValues('trainerausbildung', valuesT); showInputT = 0;" type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="inputBlock mb-3" v-if="showInput6 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.weiterbildung.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.weiterbildung.infos.description"></div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesWeiterbildung.schwerpunkt" class="form-control"
                       placeholder="Schwerpunkt">
                <label for="floatingInput">Schwerpunkt</label>
              </div>
              <div class="form-group mb-3">
                <template v-if="valuesWeiterbildung.institution">
                  <label>Institution</label>
                  <input type="text" v-model="valuesWeiterbildung.institution" class="form-control"
                         placeholder="Institution">
                </template>
                <template v-else>
                  <label>Institution</label>
                  <multiselect class="small" v-model="valuesWeiterbildung.institution_id" :id="'tags'"
                               :valueProp="'id'"
                               :options="institutions"
                               :multiple="false"
                               :label="'title'"
                               :closeOnSelect="true"
                               :allow-empty="true"
                               :create-option="false"
                               :clearOnSelect="false"
                               :searchable="true"
                               :taggable="true"
                               :placeholder="'Institution wählen'"
                               track-by="title"
                  ></multiselect>

                  <template v-if="valuesWeiterbildung.institution_id === 'new'">
                    <input class="form-control mt-2 small" type="text" v-model="valuesWeiterbildung.new_institution" placeholder="Neue Institution eintragen" /></template>
                </template>
              </div>
              <div class="form-floating mb-3">
                <input type="number" step="1" min="0" max="999" v-model="valuesWeiterbildung.lerneinheiten" class="form-control" placeholder="Lerneinheiten">
                <label for="floatingInput">Lerneinheiten</label>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.weiterbildung.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesWeiterbildung.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesWeiterbildung.oldSubmit" :values="valuesWeiterbildung.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesWeiterbildung')" v-model="activeComment"
                            name="" id="" class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesWeiterbildung');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesWeiterbildung.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesWeiterbildung.answer_id"
                      @click="postValues('weiterbildung', valuesWeiterbildung); showInput6 = 0;"
                      type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesWeiterbildung.answer_id"
                      @click="putValues('weiterbildung', valuesWeiterbildung); showInput6 = 0;"
                      type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <template v-if="valuesWeiterbildung.liga === '-- Gelöscht --'"><button v-if="valuesWeiterbildung.answer_id"
                                                                                     @click="restoreValues('weiterbildung', valuesWeiterbildung); showInput6 = 0;"
                                                                                     type="button"
                                                                                     class="btn btn-secondary float-end mb-3">  Eintrag wiederherstellen</button></template>
              <template v-else>
              <button v-if="valuesWeiterbildung.answer_id"
                      @click="deleteValues('weiterbildung', valuesWeiterbildung); showInput6 = 0;"
                      type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button></template>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="inputBlock mb-3" v-if="showInput4 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.vereinsspieler.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.vereinsspieler.infos.description"></div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpieler.verein" class="form-control"
                       placeholder="Verein">
                <label for="floatingInput">Verein</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpieler.altersklasse" class="form-control"
                       placeholder="Altersklasse">
                <label for="floatingInput">Altersklasse</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpieler.liga" class="form-control" placeholder="Liga">
                <label for="floatingInput">Liga</label>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-floating mb-3">
                    <input type="date" v-model="valuesSpieler.dateStart"
                           :max="$store.state.maxDateValue" class="form-control"
                           placeholder="Beginn">
                    <label>Beginn</label>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-floating mb-3">
                    <input type="date" v-model="valuesSpieler.dateEnd"
                           :max="$store.state.maxDateValue" class="form-control" placeholder="Ende">
                    <label>Ende</label>
                  </div>
                </div>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesSpieler.saisons" class="form-control"
                       placeholder="Saisons" min="0" step="0.50">
                <label for="floatingInput">Saisons</label>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.vereinsspieler.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesSpieler.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesSpieler.oldSubmit" :values="valuesSpieler.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesSpieler')" v-model="activeComment" name=""
                            id="" class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesSpieler');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesSpieler.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpieler.answer_id"
                      @click="postValues('vereinsspieler', valuesSpieler); showInput4 = 0;" type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpieler.answer_id"
                      @click="putValues('vereinsspieler', valuesSpieler); showInput4 = 0;" type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpieler.answer_id"
                      @click="deleteValues('vereinsspieler', valuesSpieler); showInput4 = 0;"
                      type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="inputBlock mb-3" v-if="showInput42 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.nationalspieler.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.nationalspieler.infos.description"></div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpielerNat.verband" class="form-control"
                       placeholder="Verband">
                <label for="floatingInput">Verband</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpielerNat.altersklasse" class="form-control"
                       placeholder="Altersklasse">
                <label for="floatingInput">Altersklasse</label>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label>Beginn</label>
                    <input type="date" :max="$store.state.maxDateValue"
                           v-model="valuesSpielerNat.dateStart" class="form-control"
                           placeholder="Beginn">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label>Ende</label>
                    <input type="date" :max="$store.state.maxDateValue"
                           v-model="valuesSpielerNat.dateEnd" class="form-control"
                           placeholder="Ende">
                  </div>
                </div>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesSpielerNat.saisons" class="form-control"
                       placeholder="Saisons" min="0" step="1">
                <label for="floatingInput">Spiele</label>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.nationalspieler.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesSpielerNat.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesSpielerNat.oldSubmit" :values="valuesSpielerNat.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesSpielerNat')" v-model="activeComment"
                            name="" id="" class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesSpielerNat');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesSpielerNat.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpielerNat.answer_id"
                      @click="postValues('nationalspieler', valuesSpielerNat); showInput42 = 0;"
                      type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpielerNat.answer_id"
                      @click="putValues('nationalspieler', valuesSpielerNat); showInput42 = 0;"
                      type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpielerNat.answer_id"
                      @click="deleteValues('nationalspieler', valuesSpielerNat); showInput42 = 0;"
                      type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal class="inputBlock mb-3" v-if="showInput66 === 1">
      <div class="container">
        <div class="card p-lg-5 p-3">
          <h2 class="mb-4">{{ options.internationalspieler.infos.title }} hinzufügen</h2>
          <div class="description mb-4" v-html="options.internationalspieler.infos.description"></div>
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpielerINat.verband" class="form-control"
                       placeholder="Verband">
                <label for="floatingInput">Verband</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpielerINat.altersklasse" class="form-control"
                       placeholder="Altersklasse">
                <label for="floatingInput">Altersklasse</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="valuesSpielerINat.liga" class="form-control"
                       placeholder="Liga">
                <label for="floatingInput">Liga</label>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label>Beginn</label>
                    <input type="date" :max="$store.state.maxDateValue"
                           v-model="valuesSpielerINat.dateStart" class="form-control"
                           placeholder="Beginn">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label>Ende</label>
                    <input type="date" :max="$store.state.maxDateValue"
                           v-model="valuesSpielerINat.dateEnd" class="form-control"
                           placeholder="Ende">
                  </div>
                </div>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesSpielerINat.saisons" class="form-control"
                       placeholder="Saisons" min="0" step="0.50">
                <label for="floatingInput">Saisons</label>
              </div>
              <div class="form-floating mb-3">
                <input type="number" v-model="valuesSpielerINat.pointsToCalc" class="form-control"
                       placeholder="Punkte pro Saison" min="0" step="0.25">
                <label for="floatingInput">Punkte pro Saison</label>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-floating mb-3">
                <div style="max-height: 450px; overflow-y: auto; overflow-x: hidden;">
                  <div v-for="(option, key) in options.internationalspieler.options" v-bind:key="key">
                    <input :value="option.id" v-model="valuesSpielerINat.answer_id" type="radio"
                           class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key"><span class="answerId">#{{ option.id }}</span>
                      {{ option.question }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 24px;">
            <div class="col-lg-6 col-md-12">
              <OldSubmit v-if="valuesSpielerINat.oldSubmit" :values="valuesSpielerINat.oldSubmit"/>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="editorDrafts ">
                <div class="row">
                  <div class="col-12">
                    <h4>Gründe für Änderungen</h4>
                    <select @change="handleChange('valuesSpielerINat')" v-model="activeComment"
                            name="" id="" class="form-select mb-4">
                      <option :value="{title:'', draft:''}">- Aus Vorlage wählen -</option>
                      <option :value="draft" v-for="draft in drafts" :key="draft.id">
                        {{ draft.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="form-floating mb-3">
                      <input class="form-control" v-model="activeComment.title" type="text"/>
                      <label for="floatingInput">Titel der Vorlage</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-outline-success" style="margin-top: 6px;"
                            @click="saveActiveComment('valuesSpielerINat');">
                      <template v-if="activeComment.id">Aktualisieren</template>
                      <template v-else>Neu anlegen</template>
                    </button>
                  </div>
                </div>
              </div>
              <Editor2 v-model="valuesSpielerINat.comment"/>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <button class="btn btn-warning mb-3" type="button" @click="closeInputs()">Abbrechen</button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpielerINat.answer_id"
                      @click="postValues('internationalspieler', valuesSpielerINat); showInput66 = 0;"
                      type="button"
                      class="btn btn-primary float-end mb-3">Neu anlegen
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpielerINat.answer_id"
                      @click="putValues('internationalspieler', valuesSpielerINat); showInput66 = 0;"
                      type="button"
                      class="btn btn-secondary float-end mb-3">Speichern
              </button>
            </div>
            <div class="col-3">
              <button v-if="valuesSpielerINat.answer_id"
                      @click="deleteValues('internationalspieler', valuesSpielerINat); showInput66 = 0;"
                      type="button"
                      class="btn btn-outline-danger float-end mb-3">Eintrag löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
  <div class="overviewTables">
    <template v-if="activeAnswers.vereinstaetigkeit && options && options.vereinstaetigkeit">

      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.vereinstaetigkeit.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('vereinstaetigkeit')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('vereinstaetigkeit')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4 v-if="options.vereinstaetigkeit.infos.title">{{ options.vereinstaetigkeit.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.vereinstaetigkeit">
          <tr>
            <th class="del test"></th>
            <th class="del"></th>
            <th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verein</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th class="endRight">Saisons</th>
            <th class="endRight text-center">Nachweis</th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.vereinstaetigkeit">
          <tr :class="(entry.oldSubmit) ? ' edited' : ''" :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.vereinstaetigkeit.items" :key="entry.id">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-xs btn-outline-danger"
                      @click="editEntry('vereinstaetigkeit',entry)"><i class="fa-duotone fa-edit"></i></span></td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verein }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight">{{ entry.saisons }}</td>
            <td class="endRight text-center">
              <template
                v-if="calcTypes && calcTypes.vereinstaetigkeit && calcTypes.vereinstaetigkeit.nachweis_erforderlich === 'yes'">
                <template v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" :key="index" type="button"
                          class="btn-outline-secondary btn-xs"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button" class="btn btn-sm"
                      :class="(entry.accepted === 'yes') ? 'btn-success checkedTrigger btn' : 'checkedTrigger btn btn-outline-danger'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.verbandstaetigkeit && options && options.verbandstaetigkeit">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.verbandstaetigkeit.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('verbandstaetigkeit')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('verbandstaetigkeit')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.verbandstaetigkeit.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.verbandstaetigkeit">
          <tr>
            <th class="del t2"></th>
            <th></th>
            <th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th class="endRight">Saisons</th>
            <th class="endRight text-center">Nachweis</th>
            <th class="endRight points-end">Punkte</th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.verbandstaetigkeit">
          <tr :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.verbandstaetigkeit.items" :key="entry.id">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger"
                      @click="editEntry('verbandstaetigkeit',entry)"><i class="fa-duotone fa-edit"></i></span></td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight">{{ entry.saisons }}</td>
            <td class="endRight text-center">
              <template
                v-if="calcTypes && calcTypes.verbandstaetigkeit && calcTypes.verbandstaetigkeit.nachweis_erforderlich === 'yes'">
                <template v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" :key="index" v-if="nw" type="button"
                          class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.internationaltrainer && options && options.internationaltrainer">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.internationaltrainer.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('internationaltrainer')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('internationaltrainer')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.internationaltrainer.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.internationaltrainer">
          <tr>
            <th class="del"></th>
            <th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th class="endRight">Saisons</th>
            <th class="endRight text-center">Nachweis</th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.internationaltrainer">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.internationaltrainer.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger"
                      @click="editEntry('internationaltrainer',entry)"><i class="fa-duotone fa-edit"></i></span></td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight">{{ entry.saisons }}</td>
            <td class="endRight text-center">
              <template
                v-if="calcTypes && calcTypes.internationaltrainer && calcTypes.internationaltrainer.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.vereinsspieler &&  activeAnswers.vereinsspieler.items && options && options.vereinsspieler">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.vereinsspieler.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('vereinsspieler')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('vereinsspieler')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.vereinsspieler.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.vereinsspieler &&  activeAnswers.vereinsspieler.items">
          <tr>
            <th class="del"></th>
            <th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verein</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th class="endRight">Saisons</th>
            <th class="endRight text-center"
            >
              Nachweis
            </th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.vereinsspieler &&  activeAnswers.vereinsspieler.items">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.vereinsspieler.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger" @click="editEntry('vereinsspieler',entry)"><i
              class="fa-duotone fa-edit"></i></span>
            </td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verein }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight">{{ entry.saisons }}</td>
            <td class="endRight text-center"
            >
              <template
                v-if="calcTypes && calcTypes.vereinsspieler && calcTypes.vereinsspieler.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.nationalspieler && activeAnswers.nationalspieler.items && options && options.nationalspieler">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.nationalspieler.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('nationalspieler')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('nationalspieler')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.nationalspieler.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.nationalspieler && activeAnswers.nationalspieler.items">
          <tr>
            <th class="del"></th>
            <th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Station</th>
            <th class="endRight">Spiele</th>
            <th class="endRight text-center"
            >
              Nachweis
            </th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.nationalspieler && activeAnswers.nationalspieler.items">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.nationalspieler.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger" @click="editEntry('nationalspieler',entry)"><i
              class="fa-duotone fa-edit"></i></span>
            </td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight">{{ entry.saisons }}</td>
            <td class="endRight text-center"
            >
              <template
                v-if="calcTypes && calcTypes.nationalspieler && calcTypes.nationalspieler.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.internationalspieler && options && options.internationalspieler">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.internationalspieler.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('internationalspieler')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('internationalspieler')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.internationalspieler.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.internationalspieler">
          <tr>
            <th class="del"></th>
            <th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th class="endRight">Saisons</th>
            <th class="endRight text-center"
                v-if="calcTypes && calcTypes.internationalspieler && calcTypes.internationalspieler.nachweis_erforderlich === 'yes'">
              Nachweis
            </th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.internationalspieler">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.internationalspieler.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger"
                      @click="editEntry('internationalspieler',entry)"><i class="fa-duotone fa-edit"></i></span></td>
            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td>{{ entry.saisons }}</td>
            <td class="endRight text-center"
            >
              <template
                v-if="calcTypes && calcTypes.internationalspieler && calcTypes.internationalspieler.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.ausbildung && options && options.ausbildung">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.ausbildung.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('ausbildung')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('ausbildung')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.ausbildung.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm">
          <thead v-if="activeAnswers.ausbildung">
          <tr>
            <th class="del"></th>
            <th>Schwerpunkt</th>
            <th>Institution</th>
            <th>Ausbildungsabschluss</th>
            <th class="endRight text-center">Nachweis</th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.ausbildung">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.ausbildung.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger" @click="editEntry('ausbildung',entry)"><i
              class="fa-duotone fa-edit"></i></span>
            </td>
            <td>{{ entry.schwerpunkt }}</td>
            <td>{{ entry.institution }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight text-center">
              <template
                v-if="calcTypes && calcTypes.ausbildung && calcTypes.ausbildung.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.trainerausbildung && options && options.trainerausbildung">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.trainerausbildung.items)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('trainerausbildung')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('trainerausbildung')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.trainerausbildung.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.trainerausbildung">
          <tr>
            <th class="del"></th>
            <th>Schwerpunkt</th>
            <th>Institution</th>
            <th>Ausbildungsabschluss</th>
            <th class="endRight text-center" style="width:130px">Nachweis</th>
            <th class="endRight points-end">Punkte</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.trainerausbildung">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in activeAnswers.trainerausbildung.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger"
                      @click="editEntry('trainerausbildung',entry)"><i class="fa-duotone fa-edit"></i></span></td>
            <td>{{ entry.schwerpunkt }}</td>
            <td>{{ entry.institution }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight text-center">
              <template
                v-if="calcTypes && calcTypes.trainerausbildung && calcTypes.trainerausbildung.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.points }}</td>
            <td>
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="activeAnswers.weiterbildung && options && options.weiterbildung">
      <button type="button" class="btn btn-sm btn-success float-end toggleAll"
              @click="checkAllTables(activeAnswers.weiterbildung.infos, true)">
        <i class="fa-duotone fa-circle-check"></i>
        alle
      </button>
      <button type="button" class="btn btn-sm me-3 float-end" :class="(isNotice('weiterbildung')) ? 'btn-primary' : 'btn-outline-primary'"
              @click="addNotice('weiterbildung')">
        <i class="fa-duotone fa-note-sticky"></i>
        Notizen
      </button>
      <h4>{{ options.weiterbildung.infos.title }}</h4>
      <div class="clearfix"></div>
      <div class="table-responsive">
        <table class="table table-striped table-values table-sm mb-4">
          <thead v-if="activeAnswers.weiterbildung">
          <tr>
            <th class="del"></th>
            <th></th>
            <th>Schwerpunkt</th>
            <th>Relevante Themenfelder</th>
            <th class="endRight text-center" style="width:130px"
                v-if="calcTypes && calcTypes.weiterbildung && calcTypes.weiterbildung.nachweis_erforderlich === 'yes'">
              Nachweis
            </th>
            <th class="endRight points-end " style="text-align: right;">Lerneinheiten</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="activeAnswers.weiterbildung && activeAnswers.weiterbildung.infos">
          <template v-for="OuterEntry in activeAnswers.weiterbildung.infos">
          <tr :key="entry.id" :class="(entry.oldSubmit) ? ' edited' : ''"  :data-del="(entry.liga ==='-- Gelöscht --') ? 'true' : 'false'"
              v-for="entry in OuterEntry.items">
            <td><input type="checkbox" v-model="$store.state.pluginsContent.dfb.checkAnwers[entry.id]"
                       :value="entry.id"></td>
            <td><span class="delEntry btn btn-sm btn-outline-danger" @click="editEntry('weiterbildung',entry)"><i
              class="fa-duotone fa-edit"></i></span>
            </td>
            <td>{{ entry.schwerpunkt }}</td>
            <td>{{ entry.question }}</td>
            <td class="endRight text-center">
              <template
                v-if="calcTypes && calcTypes.weiterbildung && calcTypes.weiterbildung.nachweis_erforderlich === 'yes'">
                <template :key="index" v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span> <span v-else> {{ index + 1 }}</span>
                  </button>
                </template>
              </template>
            </td>
            <td class="endRight">{{ entry.lerneinheiten }}</td>
            <td class="endRight">
              <button type="button"
                      :class="(entry.accepted === 'yes') ? 'btn-checked checkedTrigger btn btn-success btn-sm' : 'btn-not-checked btn checkedTrigger btn btn-outline-danger btn-sm'"
                      @click="toggleCheck(entry.accepted, entry.id)">
                <template v-if="entry.accepted === 'yes'">
                  <i class="fa-duotone fa-circle-check"></i>
                </template>
                <template v-else>
                  <i class="fa-duotone fa-circle"></i>
                </template>
              </button>
            </td>
          </tr>
          <tr><td></td><td></td><td colspan="2"><span style="font-weight: bold;">Institution: {{OuterEntry.title}}</span></td><td style="text-align: right;" colspan="2"><span style="font-weight: bold;">{{OuterEntry.points}} Punkte / {{OuterEntry.le}} LE</span></td><td></td></tr>
          </template>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
  <Modal v-if="activeFile && activeFile.content">
    <div class="container">
      <div class="card" style="padding:24px; max-width: 80%">
        <div class="row">
          <div class="col-lg-4">
            <button @click="download()" class="btn btn-outline-danger">Download</button>
          </div>
          <div class="col-lg-4">
            <button @click="rotateSinglePage()" class="btn btn-outline-primary">Drehen</button>
          </div>
          <div class="col-lg-4">
            <button @click="activeFile = null;" class="btn btn-outline-success float-end">Vorschau schließen
            </button>
          </div>
        </div>
        <div class="flipableWrapper">
        <vue-pdf-embed id="flipable" v-if="activeFile.contentType === 'application/pdf'"
                       :source="'data:'+activeFile.contentType+';base64,'+activeFile.content" type=""/>
        <embed v-else id="flipable" :src="'data:'+activeFile.contentType+';base64,'+activeFile.content"></div>
      </div>
    </div>
  </Modal>
  <Modal v-if="noticeActive">
    <div class="container">
      <div class="card" style="padding:24px; max-width: 80%">
        <div class="noticesContainer">

          <template v-for="n in notices">
            <div class="noticeItem" v-if="n.notice_type === noticeActive">
              <div class="noticeHeader mb-2">
                <button type="button" class="btn btn-outline-danger btn-sm float-end" @click="deleteNotice(n)"><i class="fa-duotone fa-trash"></i></button>
              </div>
              <div class="noticeMessage" v-html="n.notice"></div>
              <hr>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="columns xlarge-12 large-12 medium-12 small-12">
            <textarea style="width: 100%; min-height:200px; margin-bottom: 24px; padding: 24px;" v-model="noticeAdd" placeholder="Notiz erfassen"></textarea>
          </div>
          <div class="columns xlarge-6 large-6 medium-12 small-12">
            <button @click="noticeAdd = '';noticeActive = false" class="btn btn-outline-danger"><i class="fa-duotone fa-x"></i> Abbrechen</button>
          </div>
          <div class="columns xlarge-6 large-6 medium-12 small-12">
            <button @click="saveNotice()" class="btn btn-outline-primary float-end"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
          </div>

        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {ref} from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import {mapGetters} from "vuex";
import OldSubmit from "@/routing/plugins/dfb/subs/OldSubmit";
import Editor2 from "@/components/Editor2.vue";
import Multiselect from '@vueform/multiselect'

export default {
  name: 'DFBTables',
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  props: {
    activeAnswers: null,
    calcTypes: null,
    options: null,
    requestId: null,
    notices: []
  },
  components: {
    Editor2,
    VuePdfEmbed, OldSubmit, Multiselect
  },
  data() {
    return {
      noticeActive: false,
      noticeAdd: '',
      drafts: [],
      activeComment: {
        title: '',
        draft: ''
      },
      test: 'test',
      loadingFile: null,
      activeFile: null,
      showInput: 0,
      showInput1: 0,
      showInput2: 0,
      showInput4: 0,
      showInput42: 0,
      showInput5: 0,
      showInput6: 0,
      showInput66: 0,
      showInputT: 0,
      values: null,
      valuesNat: null,
      valuesI: null,
      valuesT: null,
      valuesWeiterbildung: null,
      valuesBildung: null,
      valuesSpieler: null,
      valuesSpielerNat: null,
      valuesSpielerINat: null,
      degree: 0,
      institutions: [],
    }
  },
  emits: ["refreshActiveRequest"],
  computed: {

    ...mapGetters(["getApiUrl"])
  },
  methods: {
    isNotice(type) {
      return this.notices.filter(n => n.notice_type === type).length > 0;
    },
    deleteNotice(notice){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "plugins/dfb/requestNotice/"+notice.id, {headers}).then(() => {
        this.$emit('refreshActiveRequest');
        this.noticeActive = false;
      });

    },
    saveNotice(){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/requestNotice/"+this.requestId, {type: this.noticeActive, notice: this.noticeAdd}, {headers}).then(() => {
        this.$emit('refreshActiveRequest');
        this.noticeActive = false;
        this.noticeAdd = '';
      });
    },
    addNotice($type) {
      this.noticeActive = $type;
    },
    getInsititutions() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/institutions", {headers}).then((r) => {
        this.institutions = r.data.result.data;
      });
    },

    getDraftReasons() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?group_id=35", {headers}).then((r) => {
        this.drafts = r.data.result.data;
        this.loading = false;
      });
    },
    saveActiveComment(type) {
      this.$log.debug("saving comment in", type);
      this.$log.debug(this.activeComment);
      this.$log.debug(this.$data[type].comment);
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.$log.debug(this.$data);
      let payload = {
        title: this.activeComment.title,
        draft: this.$data[type].comment
      }
      if (this.activeComment.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "draft/" + this.activeComment.id, payload, {headers}).then(() => {
          // trigger Custom Event Dom refreshTasksDFB
          this.getDraftReasons();
          alert("gespeichert!");
        }).catch(() => {
        });
      } else {
        payload.group_id = 35;
        axios.post(this.$store.state.apiUrl + "draft/", payload, {headers}).then(() => {
          this.getDraftReasons();
          alert("gespeichert!");
        }).catch(() => {
        });
      }
    },
    download() {
      // force download pdf file
      var a = document.createElement("a"); //Create <a>
      a.href = "data:" + this.activeFile.contentType + ";base64," + this.activeFile.content; //Image Base64 Goes here
      a.download = this.activeFile.filename; //File name Here
      a.click(); //Downloaded file
    },
    rotateSinglePage() {
      this.degree = this.degree + 90;
      var viewerMain = document.getElementById("flipable");
      viewerMain.style.transform = "rotate(" + this.degree + "deg)";
    },
    openFile(entry, index) {
      this.loadingFile = entry.id + index;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + 'plugins/dfb/readFile/' + entry.id + '/' + index, {headers}).then((response) => {
        let shortReport = response.data.result;
        this.$log.debug("reading file");
        this.$log.debug(shortReport);
        this.activeFile = {
          entryId: entry.id,
          index: index,
          filename: shortReport.data.filename,
          content: shortReport.data.content,
          contentType: shortReport.data.contentType
        };
        this.loadingFile = null;
      }).catch((e) => {
        this.$log.debug(e);
        this.loadingFile = null;
      });
    },
    checkAllTables(ids, weiterbildung) {
      if (weiterbildung !== undefined) {
        let newIds = [];
        let i;
        let j;
        for (i in ids) {
         for(j in ids[i].items){
           newIds.push(ids[i].items[j]);
         }
        }

        ids = newIds;
      }

      axios.put(this.$store.state.apiUrl + 'plugins/dfb/setAcceptedAnswer/', {ids: ids}, {headers: {"crmAuth": localStorage.getItem("sketchManagerToken")}}).then(() => {
        this.$emit('refreshActiveRequest');
      }).catch((e) => {
        this.$log.debug(e);
      });

    },
    toggleCheck(accepted, id) {
      axios.put(this.$store.state.apiUrl + 'plugins/dfb/setAcceptedAnswer/', {
        accepted: accepted,
        id: id
      }, {headers: {"crmAuth": localStorage.getItem("sketchManagerToken")}}).then(() => {
        this.$emit('refreshActiveRequest')
      }).catch((e) => {
        this.$log.debug(e);
      });
    },
    closeInputs() {
      this.showInput = 0;
      this.showInput1 = 0;
      this.showInput2 = 0;
      this.showInput4 = 0;
      this.showInput42 = 0;
      this.showInput5 = 0;
      this.showInput6 = 0;
      this.showInput66 = 0;
      this.showInputT = 0;
      this.activeComment = {
        title: '',
        draft: ''
      }
    },
    editEntry(type, entry) {
      // reset All
      this.values = null;
      this.valuesNat = null;
      this.valuesI = null;
      this.valuesT = null;
      this.valuesSpieler = null;
      this.valuesSpielerNat = null;
      this.valuesSpielerINat = null;
      this.valuesWeiterbildung = null;
      this.valuesBildung = null;
      this.closeInputs();
      if (entry.comment_id) {
        let a;
        for (a in this.drafts) {
          if (this.drafts[a].id === entry.comment_id) {
            this.activeComment = this.drafts[a];
          }
        }
      }
      if (type === 'vereinstaetigkeit') {
        this.values = entry;
        this.showInput = 1;
      }
      if (type === 'verbandstaetigkeit') {
        this.valuesNat = entry;
        this.showInput1 = 1;
      }
      if (type === 'internationaltrainer') {
        this.valuesI = entry;
        this.showInput2 = 1;
      }
      if (type === 'ausbildung') {
        this.valuesBildung = entry;
        this.showInput5 = 1;
      }
      if (type === 'trainerausbildung') {
        this.valuesT = entry;
        this.showInputT = 1;
      }
      if (type === 'weiterbildung') {
        this.valuesWeiterbildung = entry;
        this.showInput6 = 1;
      }
      if (type === 'vereinsspieler') {
        this.valuesSpieler = entry;
        this.showInput4 = 1;
      }
      if (type === 'nationalspieler') {
        this.valuesSpielerNat = entry;
        this.showInput42 = 1;
      }
      if (type === 'internationalspieler') {
        this.valuesSpielerINat = entry;
        this.showInput66 = 1;
      }
    },
    handleChange(type) {
      this.$log.debug("typing", this.activeComment);
      this.$log.debug("to", type);
      if (this.activeComment.id) {
        // clone this.activeComment
        let comment = Object.assign({}, this.activeComment);
        if (comment.draft) {
          this.$data[type].comment = comment.draft;
        } else {
          this.$data[type].comment = comment.title;
        }
      }
    },
    postValues: function (postType, values) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      values.comment_id = this.activeComment.id;
      axios.post(this.getApiUrl + 'plugins/dfb/' + postType, values, {
        headers
      })
        .then((response) => {
          this.closeInputs();
          this.$emit("refreshActiveRequest");
          this.getInsititutions();
        })
        .catch(error => {
          this.$store.dispatch('handleApiError', error);
        })
    },
    putValues: function (postType, values) {
      values.todo = "putIt";
      this.postValues(postType, values);
    },
    deleteValues: function (postType, values) {
      values.todo = "delIt";
      this.postValues(postType, values);
    },
    restoreValues: function (postType, values) {
      values.todo = "restoreIt";
      this.postValues(postType, values);
    },
  },
  created() {
    this.getDraftReasons();
    this.getInsititutions();
  },
}
</script>
<style lang="scss" scoped>
.dfb.plugin-list table tr.edited td {
  background-color: #d4d4d4 !important;
  --bs-table-accent-bg: #d4d4d4 !important;
}

.ps {
  height: 400px;
}

.inline table td, .inline table th {
  white-space: normal;
  font-size: 14px;
  &:last-child {
    width: 32px;
  }
}

.inline table td:last-child {
  text-align: right;
}

embed {
  max-width: 100%;
  height: auto;
}

.checkedTrigger btn {
  padding: 3px 4px;
  width: 24px;
  height: 24px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
}

button.btn.btn-sm.btn-success.float-end.toggleAll {
  padding: 5px 12px;
}

.clearfix {
  float: left;
  width: 100%;
  clear: both;
  display: block;
}

.overviewTables th:last-child {
  width: 40px;
}

th.del {
  width: 35px;
}

th.begin-end {
  width: 107px;
}

th.endRight {
  width: 110px;
}

th.endRight, td.endRight {
  text-align: right;
}

table.table-sm th {
  font-size: 13px;
}

table.table-sm td {
  vertical-align: middle;
}

table.table-sm td:first-child {
  text-align: left;
  line-height: 14px;
}
.flipableWrapper{
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
tr.edited[data-del='true'] {
  opacity: 0.5;
}

</style>
