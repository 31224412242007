<template>
  <div class="appView activities">


      <div class=" activities">
        <div class="row mb-3">

          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label class="color">Von</label>
              <VudeDatePicker class="small" placeholder="Von" v-model="date_from" :vertical="true" model-type="yyyy-MM-dd"  format="dd.MM.yyyy" locale="de"  :auto-apply="true" :close-on-auto-apply="true" :enable-time-picker="false"  cancel-text="Schließen" select-text="Auswählen" :teleport="true" show-now-button now-button-label="Heute"  @update:model-value="filter();$store.dispatch('toast', {type: 'success', message: 'Die Aktivitäten wurden gefiltert.'});"  />
            </div>

              </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label class="color">Bis</label>
              <VudeDatePicker class="small" v-model="date_to" placeholder="Bis" :vertical="true" model-type="yyyy-MM-dd"   format="dd.MM.yyyy" locale="de"  :auto-apply="true" :close-on-auto-apply="true" :enable-time-picker="false"  cancel-text="Schließen" select-text="Auswählen" :teleport="true" show-now-button now-button-label="Heute"  @update:model-value="filter();$store.dispatch('toast', {type: 'success', message: 'Die Aktivitäten wurden gefiltert.'});"  />
            </div>


            </div>
          <div class="col-12 col-md-12 col-lg-3">
            <!-- add filter search -->
            <div class="form-group">
              <input type="text" class="form-control small " style="margin-top: 20px;" v-model="filter_search" @blur="filterSearchword" @keyup.enter="filterSearchword" placeholder="Suche">
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-3">
          <div class="form-group">
            <multiselect v-model="limit"     :options="listLimits" v-if="limit"
                         :multiple="false"
                         :canClear="false"
                         :closeOnSelect="true"
                         :placeholder="'Anzahl wählen'"
                         @update:model-value="filter"
                         style="width: 100px;margin-top: 20px"
                         class="form-select small float-end">

            </multiselect>
          </div>
          </div>
          </div>

          <div class="activityWrap" v-if="activities.length">
            <table class="table-hover table-sm table">
              <thead>
              <tr>
                <th class="center">Mitarbeiter</th>
                <th>Zeit</th>
                <th class="center">Typ</th>
                <th>Ereignis</th>
                <th>Link</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="activity in activities" v-bind:key="activity.id">
                <td class="center">
                  <img :src="$filters.employeeIcon(getEmployees, activity.u_id, 'u_id')" class="sketchAvatar">
                </td>
                <td>{{ $filters.formatDateTime(activity.a_time) }}</td>
                <td class="center">
                  <template v-if="activity.a_type === 'document'||activity.a_type === 'voucher'||activity.a_type === 'document_queue' || activity.a_type ==='documentactivation'"><span tooltip="Dokument" style="color: #0088CC;"><i class="fa-duotone fa-file"></i></span></template>
                  <template v-else-if="activity.a_type === 'payment'"><span tooltip="Bezahlung" style="color: #b21f28;"><i class="fa-duotone fa-euro-sign"></i></span></template>
                  <template v-else-if="activity.a_type === 'payment'"><span tooltip="Bezahlung"  style="color: #b21f28;"><i class="fa-duotone fa-euro-sign"></i></span></template>
                  <template v-else-if="activity.a_type === 'phone_incoming'"><span tooltip="Anruf" style="color: #0088CC;"><i class="fa-duotone fa-phone-alt"></i></span></template>
                  <template v-else-if="activity.a_type === 'archive_created'"><span tooltip="Archiv"  style="color: #0088CC;"><i class="fa-duotone fa-archive"></i></span></template>
                  <template v-else-if="activity.a_type === 'changeDBOpos'|| activity.a_type ==='customersepa'"><span tooltip="Buchhaltung" style="color: #b21f28;"><i class="fa-receipt fa-duotone"></i></span></template>
                  <template v-else-if="activity.a_type === 'project_updated'||activity.a_type === 'project_created'||activity.a_type === 'address'||activity.a_type === 'customernotice'||activity.a_type === 'customer_reactivated'||activity.a_type === 'customer_ownerChanged'||activity.a_type === 'customer_created'||activity.a_type === 'customer_queue'||activity.a_type === 'customersnoozeDate'||activity.a_type === 'customer_akquiseChanged'||activity.a_type === 'customerdeleted'||activity.a_type === 'customer'||activity.a_type === 'customer_updated'"><span tooltip="Kunde" style="color: #0088CC;"><i class="fa-users fa-duotone"  ></i></span></template>
                  <template v-else-if="activity.a_type === 'customer_file_upload'|| activity.a_type ==='customercustomFile'|| activity.a_type ==='customercustomFiles'"><span tooltip="Datei" style="color: #0088CC;"><i class="fa-file fa-duotone"></i></span></template>
                  <template v-else-if="activity.a_type === 'employee_queue'"><span tooltip="Mitarbeiter" style="color: #3B8130;"><i class="fa-id-card-clip fa-duotone"></i></span></template>
                  <template v-else-if="activity.a_type === 'customernewsletterUnsub'"><span tooltip="Newsletter" style="color: #0088CC;"><i class="fa-duotone fa-mail-bulk"></i></span></template>
                  <template v-else>
                   <span class="badge small">{{ activity.a_type }}</span>
                  </template>

                </td>
                <td v-html="activity.a_description"></td>
                <td>
                  <template v-if="activity.a_type === 'task' && activity.task_id">
                    <router-link class="btn btn-outline-primary btn-sm me-2" :to="{name: 'TasksDetails', params: {id: activity.task_id }}"><i class="fa-duotone fa-ticket"></i> Ticket</router-link>

                  </template>
                  <template v-if="activity.a_type === 'document' && activity.document_id">

                    <router-link class="btn btn-outline-primary btn-sm me-2" :to="{name: 'DocumentsDetails', params: {id: activity.document_id }}"><i class="fa-duotone fa-file"></i> Dokument</router-link>
                  </template>
                  <template v-if="activity.c_id">
                  <router-link class="btn btn-outline-primary btn-sm" :to="{name: 'CustomersTabs', params: {id: activity.c_id, tab: 'contact' }}"><i class="fa-duotone fa-user"></i> Kunde</router-link>
                </template> </td>
              </tr>
              </tbody>
            </table>


          </div>


      </div>
    </div>
</template>
<style>
.img-employee {
  width: 50px;
}
</style>
<script>
import { ref } from 'vue';
import axios from 'axios';
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import Multiselect from '@vueform/multiselect'
const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]

export default {
  name: 'ActivitiesOverview',
  components: {VueDatePicker, Multiselect},
  computed: {
    ...mapGetters(["getEmployees"]),
  },
  methods: {
    /*
    setAbonnement(abonnement) {

      this.activeProduct = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});

    },
    */
    filterSearchword() {
       this.filter(this.filter_search);
      this.getActivities();
    },
    filter(searchword) {
      let VueInner = this;
      this.loading = true;
      if (searchword) {
        this.filters = '&filter_search=' + searchword;
      } else {
        this.filters = '';
      }
      if (this.date_from) {
        this.filters += '&date_from=' + this.date_from;
      }
      if (this.date_to) {
        this.filters += '&date_to=' + this.date_to;
      }
      this.getActivities();
      // toast success


    },
    getActivities() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "activity/search?limit="+ this.limit + this.filters, {headers}).then((r) => {
        this.activities = r.data.result.data;
        this.loading = false;
      });
    },
  },
  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const isoTomorrow = tomorrow.toISOString().substr(0, 10);
    return {
      activities: [],
      date_from: new Date().toISOString().substr(0, 10),
      date_to: isoTomorrow,
      filters: null,
      loading: false,
      list: 'activities',
      listLimits: [25, 50, 100,200,250,500],
      limit: 100,
      filter_search: '',
    };
  },
  created() {
    this.a = this.activities;
    this.filter();
    /*
    let Vueinner = this;
    this.loading = true;
    const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
    */
    //this.getActivities();
  },
}
</script>
