<template>
  <div class="card"   >
    <div class="card-header">
      <h6>Neue Kontakte</h6>
    </div>

      <template v-if="contacts && contacts.length > 0">
        <ul class="list-group cursorlist" style="margin-left: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0;">
          <li v-for="contact in contacts" class="list-group-item text-xs" @click="routeToContact(contact.id)">

            <template v-if="contact.c_company">
              <span class="docinfo">{{ contact.c_company }}</span>
              <span class="text-xs documentInfo"
                    v-if="contact.c_title || contact.c_firstname || contact.c_lastname">
          <template v-if="contact.c_title">{{ contact.c_title }}&nbsp;</template>
          <template v-if="contact.c_firstname">{{
              contact.c_firstname
            }}&nbsp;</template>
          <template v-if="contact.c_lastname">{{ contact.c_lastname }}</template>
        </span>
            </template>
            <template v-else>
          <span class="docinfo">
          <template v-if="contact.c_title">{{ contact.c_title }}&nbsp;</template>
          <template v-if="contact.c_firstname">{{
              contact.c_firstname
            }}&nbsp;
          </template>
          <template v-if="contact.c_lastname">{{ contact.c_lastname }}</template></span>
            </template>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="card-body"><small>Keine neuen Kontakte gefunden.</small></div>
      </template>

  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'tasksWeekly',
  components: {
  },
  data() {
    return {
      activeEmployeeTab: 'contacts',
      contacts: [],
      loading: false,
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getContactsWeekly() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/waiting" + this.filter, {headers}).then((r) => {
        VueInner.contacts = r.data.result.data;
        VueInner.loading = false;
      });
    },
    routeToContact(id){
      let VueInner = this;
      this.$store.dispatch("setActiveCustomer",id).then(function () {
        VueInner.$router.push({name: "CustomersDetails", params: {id: id}});
      });


    }

  },
  watch: {
    filter: function (newVal, oldVal) {
      this.getContactsWeekly();
    },

  },
  created() {
    this.getContactsWeekly();
  },
}
</script>
