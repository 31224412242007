<template>
  <div class="modal fade show " tabindex="-1"
       aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg purpleView">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("projects.newTask") }}</h5>
          <div class="btn-group" style="margin-left: auto;">
            <template v-if="loading">
              <button type="button" class="btn btn-sm btn-outline-primary" disabled>
                <i class="fa-duotone fa-spinner fa-spin"></i> {{ $t("globals.save") }}
              </button>
              <button type="button" class="btn btn-sm btn-outline-primary" disabled>
                <i class="fa-duotone fa-spinner fa-spin"></i> {{ $t("globals.saveAndOpen") }}
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-sm btn-outline-primary" @click.once="postTask('')">
                <i class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
              </button>
              <button type="button" class="btn btn-sm btn-outline-primary" @click.once="postTask('open')">
                <i class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.saveAndOpen") }}
              </button>
            </template>
          </div>
          <button type="button" class="btn-close ms-3" @click="closeTask"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="projectTitle">{{ $t("globals.title") }}</label>
            <input type="text" class="form-control" id="projectTitle" v-model="newTaskItem.title">
          </div>
          <div class="form-group">
            <label for="projectDesc">{{ $t("globals.description") }}</label>
            <Editor2 v-model="newTaskItem.description"></Editor2>
          </div>
          <div class="row">
            <div class="col-4">
              <label>{{ $t("tasks.create.start") }}</label>
              <VueDatePicker class="small" v-model="newTaskItem.start_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                             :placeholder="this.$t('tasks.create.startDate')" :auto-apply="true"
                             :close-on-auto-apply="true" locale="de"
                             :enable-time-picker="false" cancel-text="X" :select-text="this.$t('globals.choose')"
                             :teleport="true"></VueDatePicker>
            </div>
            <div class="col-4">
              <label>{{ $t("globals.submission") }}</label>
              <VueDatePicker class="small" v-model="newTaskItem.due_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                             :placeholder="this.$t('tasks.create.submissionDate')" :auto-apply="true"
                             :close-on-auto-apply="true" locale="de"
                             :enable-time-picker="false" cancel-text="X" select-text="this.$t('globals.choose')"
                             :teleport="true"></VueDatePicker>
            </div>
            <div class="col-4">
              <label>{{ $t("globals.processing") }}</label>
              <VueMultiselect class="small" :options="getEmployees" v-model="newTaskItem.employee_id" :multiple="false"
                              valueProp="id"
                              modelValue="id" :valueProp="'id'" :close-on-select="true" :clear-on-select="false"
                              :preserve-search="true"
                              track-by="e_name" :isOpen="true"
                              :placeholder="this.$t('globals.editor')" label="e_name" :track-by="'id'"
                              :allow-empty="false"
                              :select-label="''" :taggable="false"
              ></VueMultiselect>
            </div>
          </div>
          <div class="row" v-if="!customerActive || !customerActive.hash">
            <div class="col-6">
              <label>{{ $t("globals.customer") }}</label>
              <customersearch v-model="newTaskItem.customer" @changed-customer="getProjects" :small="true"/>
            </div>
            <div class="col-6" v-if="projects && projects.length">
              <label>{{ $t("globals.project") }}</label>
              <VueMultiselect class="small" :options="projects" v-model="newTaskItem.project" :multiple="false"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :placeholder="this.$t('globals.project')" label="title" :track-by="'title'"
                              :allow-empty="true" openDirection="bottom"
                              :select-label="''" :hide-selected="true"
                              :show-no-results="true" :options-limit="300"
                              :limit="1" :disabled="false" :taggable="false"
              ></VueMultiselect>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-6">
              <label style="display: block;">{{ $t("globals.customer") }}</label>
              <strong>
                <template v-if="customerActive.c_company">
                  {{ customerActive.c_company }}
                  <span class="text-xs customerInfo"
                        v-if="customerActive.c_title || customerActive.c_firstname || customerActive.c_lastname">
          <template v-if="customerActive.c_title">{{ customerActive.c_title }}&nbsp;</template>
          <template v-if="customerActive.c_firstname">{{ customerActive.c_firstname }}&nbsp;</template>
          <template v-if="customerActive.c_lastname">{{ customerActive.c_lastname }}</template>
        </span>
                </template>
                <template v-else>
                  <template v-if="customerActive.c_title">{{ customerActive.c_title }}&nbsp;</template>
                  <template v-if="customerActive.c_firstname">{{ customerActive.c_firstname }}&nbsp;</template>
                  <template v-if="customerActive.c_lastname">{{ customerActive.c_lastname }}</template>
                </template>
              </strong>
            </div>
            <div class="col-6" v-if="projectsProp && projectsProp.length">
              <label>{{ $t("globals.project") }}</label>
              <VueMultiselect class="small" :options="Object.values(projectsProp)" v-model="newTaskItem.project"
                              :multiple="false"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              ref="multiselect_projects"
                              placeholder="Projekt wählen" label="title" :track-by="'title'"
                              :allow-empty="true" openDirection="bottom"
                              select-label="Projekt wählen" :taggable="false"
                              :valueProp="'id'"
                              :showNoOptions="false"
              ></VueMultiselect>
            </div>
            <div class="col-6" v-else-if="projects && projects.length">
              <label>{{ $t("globals.project") }}</label>
              <VueMultiselect class="small" :options="projects" v-model="newTaskItem.project" :multiple="false"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :placeholder="this.$t('globals.project')" label="title" :track-by="'title'"
                              :allow-empty="true" openDirection="bottom"
                              :select-label="''" :hide-selected="true"
                              :show-no-results="true" :options-limit="300"
                              :limit="1" :disabled="false" :taggable="false"
              ></VueMultiselect>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-lg-4">
              <label class="color">{{ $t("globals.priority") }}</label>
              <VueMultiselect class="small" :options="Object.values(getConfigs.issue_prios)"
                              v-model="newTaskItem.priority_id"
                              :multiple="false" :valueProp="'id'" ref="multiselect_prio"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :isOpen="true" track-by="title" openDirection="bottom"
                              :placeholder="this.$t('projects.choosePriority')" label="title" :allow-empty="false"
                              :select-label="this.$t('projects.choosePriority')"
              ></VueMultiselect>
            </div>
            <div class="col-12 col-lg-4">
              <label class="color">{{ $t("globals.tracker") }}</label>
              <VueMultiselect class="small" :options="Object.values(getConfigs.issue_trackers)"
                              v-model="newTaskItem.tracker_id"
                              :multiple="false" :valueProp="'id'" ref="multiselect_tracker"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :isOpen="true" track-by="title" openDirection="bottom"
                              :placeholder="this.$t('tasks.details.chooseTracker')" label="title" :allow-empty="false"
                              :select-label="this.$t('tasks.details.chooseTracker')"
              ></VueMultiselect>
            </div>
            <div class="col-12 col-lg-4">
              <label class="color">Geschätzter Zeitaufwand</label>
              <input type="number" class="form-control small" v-model="newTaskItem.estimated_hours" step="0.25"/>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-lg-6">
              <label class="color">{{ $t("globals.status") }}</label>
              <VueMultiselect class="small" :options="Object.values(getConfigs.issue_states)"
                              v-model="newTaskItem.state_id"
                              :multiple="false" :valueProp="'id'" ref="multiselect_state"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              track-by="title" openDirection="bottom"
                              :placeholder="this.$t('tasks.details.chooseStatus')" label="title" :allow-empty="false"
                              :select-label="this.$t('tasks.details.chooseStatus')"
              ></VueMultiselect>
            </div>
            <div class="col-12 col-lg-6"
                 v-if="addresses && newTaskItem && newTaskItem.customer && newTaskItem.customer.id">
              <label>Ansprechpartner</label>
              <div style="position: relative">
                <VueMultiselect class="small isMultiselect" :options="Object.values(addresses)"
                                v-model="newTaskItem.followers.outer"
                                :multiple="true" :valueProp="'id'" ref="multiselect_extfollower"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                :isOpen="true" track-by="id" openDirection="bottom"
                                :placeholder="this.$t('tasks.details.chooseContactPerson')" label="_customerName"
                                :showNoOptions="false"
                                :select-label="this.$t('tasks.details.chooseContactPerson')"
                ></VueMultiselect>
              </div>
            </div>
          </div>
          <div v-if="getMenu && getMenu.events" class="row">
            <div class="columns xlarge-12 large-12 medium-12 small-12">
              <div class="form-group">
                <h6>{{ $t("sketch.eventAccess") }}</h6>
                <div class="btn-group me-3">
                  <input type="radio" value="0" class="btn-check" id="products-read-none" autocomplete="off"
                         v-model="newTaskItem.fillForDates">
                  <label class="btn btn-outline-primary" for="products-read-none">{{ $t("globals.no") }}</label>
                  <input type="radio" value="1" class="btn-check" id="products-read-yes" autocomplete="off"
                         v-model="newTaskItem.fillForDates">
                  <label class="btn btn-outline-primary" for="products-read-yes">{{ $t("globals.yes") }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor2 from "@/components/Editor2.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from "axios";
import {mapGetters} from "vuex";
import VueMultiselect from "vue-multiselect";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: "Create",
  computed: {
    // ...mapGetters(["customerActive", "stateFilter", "projectFilter", "getEmployees"]),
    ...mapGetters(["customerActive", "getEmployees", "getMenu", "getConfigs", "getUser"]),
  },
  components: {customersearch, Editor2, VueDatePicker, VueMultiselect},
  props: ['projectsProp', 'comefrom', 'selectedProject'],
  data() {
    return {
      newTaskItem: {
        title: "",
        description: "",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: "",
        project: '',
        task_id: '',
        priority_id: {
          id: '',
          title: ''
        },
        tracker_id: {
          id: '',
          title: ''
        },
        state_id: {
          id: '',
          title: ''
        },
        estimated_hours: '',
        employee_id: {
          id: '',
          e_name: ''
        },
        fillForDates: 0,
        customer: {
          id: '',
          hash: '',
          full_name: ''
        },
        followers: {
          outer: []
        }
      },
      loading: false,
      addresses: [],
      projects: []
    };
  },
  methods: {
    prefillNewTask() {
      if (this.getUser) {
        this.newTaskItem.employee_id.id = this.getUser._isEmployee;
        this.newTaskItem.employee_id.e_name = this.getUser.employee.e_name;
      }
      this.newTaskItem.priority_id.id = '12';
      this.newTaskItem.priority_id.title = 'Normal';
      this.newTaskItem.tracker_id.id = '4';
      this.newTaskItem.tracker_id.title = 'Leistung (ohne Angebot)';
      this.newTaskItem.state_id.id = '5';
      this.newTaskItem.state_id.title = 'Offen';
    },
    postTask(action) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.loading = true;
      let VueInner = this;
      let hash;
      if (this.customerActive && this.customerActive.hash) {
        hash = this.customerActive.hash;
      } else {
        hash = this.newTaskItem.customer.hash;
      }
      delete this.newTaskItem.customer;
      if (this.newTaskItem.employee_id) {
        this.newTaskItem.employee_id = this.newTaskItem.employee_id.id;
      }
      this.correctId('priority_id');
      this.correctId('tracker_id');
      this.correctId('state_id');
      let postContent = {
        hash: hash,
        data: this.newTaskItem,
      }
      if (this.newTaskItem && this.newTaskItem.project && this.newTaskItem.project.id) {
        postContent.data.project_id = this.newTaskItem.project.id;
      } else {
        postContent.data.project_id = 0;
      }

      axios.post(this.$store.state.apiUrl + "issue/", postContent, {headers}).then((r) => {
        this.closeTask();
        if (this.comefrom === 'customer') {
          this.$store.dispatch("tasksFilterCustomer");
        } else {
          this.$store.dispatch("tasksFilter");
        }
        if (action === 'open') {
          VueInner.$router.push({name: "TasksTabs", params: {id: r.data.result.id, tab: 'details'}});
        }
        VueInner.loading = false;
        VueInner.$store.dispatch('toast', {type: 'success', message: 'Das Ticket wurde erfolgreich angelegt.'});
      }).catch((r) => {
        this.loading = false;
        this.$store.dispatch("handleApiResponse", r.response);
      });
    },
    correctId(key) {
      this.newTaskItem[key] = this.newTaskItem[key].id;
    },
    closeTask() {
      this.$emit('close-task');
      this.$store.commit("closeModal", "task");
    },
    getProjects() {

      if (this.customerActive) {
        this.newTaskItem.customer = this.customerActive;
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (this.newTaskItem.customer && this.newTaskItem.customer.id) {
        axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.newTaskItem.customer.id + "&filter_state=active&limit=0", {headers}).then((r) => {
          this.projects = r.data.result.data;
        });
        this.getAddresses()
      } else {
        this.projects = [];
        this.addresses = [];
      }
    },
    getAddresses() {
      if (this.newTaskItem.customer.id) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "customer/" + this.newTaskItem.customer.id + "/addresses", {headers}).then((r) => {
          const result = Object.values(r.data.result.data);
          this.addresses = result.filter(a => (a.customer_joomla !== null && a.customer_joomla !== ''));
        });
      }
    },
  },
  created() {
    if (this.taskActive) {
      this.newTaskItem = this.taskActive;
    }
    // set timeout, then get projects
    setTimeout(() => {
      this.getProjects();
    }, 500);
    if (this.newTaskItem.customer && this.newTaskItem.customer.id || this.customerActive) {

      if (this.selectedProject) {
        if (this.projectsProp && this.projectsProp.length) {
          this.newTaskItem.project = this.projectsProp.find(p => p.id === this.selectedProject);
        }
      }
    }
    this.prefillNewTask();
  },
  mounted() {
  },
  watch: {
    newTaskItem: {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  }
}
</script>

